import React, { useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { MdClose } from 'react-icons/md';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import size from 'lodash/size';
import trim from 'lodash/trim';
import { OpenEndedInteractionConfigurationT } from '../../model/Interaction.ts';
import { saveOpenEndedEntry } from '../../service/EntryService.ts';
import { QUESTION_INTERACTION_ANSWER_MAX_LENGTH } from '../../../../util/Constants.ts';
import { InteractionWrapper } from '../InteractionWrapper';
import { InteractionContainer } from '../InteractionContainer';
import { InteractionProps } from '../Interaction/Interaction.tsx';
import { validateMaxLength, validateRequired } from '../../validation/InteractionValidation.ts';
import { hasSubmission, recordSubmission } from '../../service/InteractionSubmissionTracker.ts';
import { translate } from '../../../../translate';
import { AlertBox } from '../../../commons/components/AlertBox';
import { Input } from '../../../../components/Input';
import { FiPlus } from 'react-icons/fi';
import { IconButton } from '../../../../components/IconButton';

interface EntryT {
	value: string;
}

interface FormValues {
	entries: EntryT[];
}

export const QuestionInteraction: React.FC<InteractionProps> = ({ presentationId, interaction, isDisabled, onInteractionEnd }) => {
	const config = interaction.configuration as OpenEndedInteractionConfigurationT;
	const alreadySubmitted = hasSubmission(interaction.id);
	const required = true; //interaction.required;
	const {
		register,
		handleSubmit,
		control,
		formState: { isSubmitting, errors },
		watch,
		reset,
	} = useForm<FormValues>({
		defaultValues: {
			entries: [],
		},
	});

	const { fields, append, remove } = useFieldArray({
		name: 'entries',
		control,
		rules: {
			required: required,
			maxLength: config.maximumAnswers,
		},
	});

	useEffect(() => {
		if (isEmpty(fields)) {
			append({ value: '' }, { shouldFocus: false });
		}
	}, [fields, append]);

	const canAdd = fields.length < config.maximumAnswers;
	const canRemove = fields.length > 1;

	const onSubmit = async (data: FormValues) => {
		const entries = data.entries.map((x: EntryT) => x.value);
		const filtered = filter(map(entries, trim), size) as string[];
		if (!isEmpty(filtered)) {
			await saveOpenEndedEntry(presentationId, interaction.id, filtered);
			recordSubmission(interaction.id);
			reset();
			onInteractionEnd();
		} else if (alreadySubmitted || !required) {
			onInteractionEnd();
		}
	};

	const handleAdd = () => {
		if (canAdd) {
			append({ value: '' }, { shouldFocus: false });
		}
	};

	const handleRemove = (index: number) => () => {
		if (canRemove) {
			remove(index);
		}
	};
	return (
		<InteractionWrapper interaction={interaction} onSubmit={handleSubmit(onSubmit)} isSubmitDisabled={isSubmitting || isDisabled}>
			<InteractionContainer>
				<div className='flex flex-col gap-2 p-2'>
					{/* Error Message Alert Box */}
					{!isEmpty(errors.entries) && errors.entries[0]?.value?.message && <AlertBox title={errors.entries[0]?.value?.message} />}

					{/* Input Fields */}
					{fields.map((field, index) => (
						<div key={field.id} className='flex flex-col mb-1 relative'>
							<Input
								id={field.id}
								maxLength={QUESTION_INTERACTION_ANSWER_MAX_LENGTH}
								placeholder={index === 0 ? config.placeholder : ''}
								autoComplete='off'
								className='pe-9'
								disabled={isDisabled}
								{...register(`entries.${index}.value`, {
									onChange: () => {
										const emptyField = watch('entries').some((value) => value.value === '');
										if (!emptyField && canAdd) {
											handleAdd();
										}
									},
									validate: {
										required: (value) => {
											const filledAtLeastOneValue = watch('entries').some((value) => value.value !== '');
											const isRequired = required && !filledAtLeastOneValue;
											return isRequired && !alreadySubmitted ? validateRequired(value, translate('Please share an answer before submitting')) : undefined;
										},
										maxLength: (value) => validateMaxLength(value, 500, translate('Field too long')),
									},
								})}
							/>
							{index !== 0 && (
								<button type='button' className='absolute right-2 top-1/2 transform -translate-y-1/2 p-2 text-brand' disabled={isDisabled} onClick={handleRemove(index)}>
									<MdClose className='w-5 h-5' />
								</button>
							)}
						</div>
					))}

					{/* Add Button */}
					{canAdd && (
						<div className='flex justify-end px-3 py-0 my-2'>
							<IconButton aria-label='Add' onClick={handleAdd} disabled={isDisabled} icon={<FiPlus />} />
						</div>
					)}
				</div>
			</InteractionContainer>
		</InteractionWrapper>
	);
};
