import { ButtonHTMLAttributes, CSSProperties, ReactNode } from 'react';
import classes from './IconButton.module.css';
import { useTheme } from '../../theme/components/QuestioryThemeProvider/QuestioryThemeProvider.tsx';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
	icon: ReactNode;
	variant?: 'normal' | 'inverted';
}

export function IconButton({ icon, className, style, onClick, variant = 'normal', ...props }: Props) {
	const { colors } = useTheme();
	const styles =
		variant === 'normal'
			? {
					'--background-color': colors.buttonColor,
					'--text-color': colors.buttonTextColor,
				}
			: {
					'--background-color': colors.buttonTextColor,
					'--text-color': colors.buttonColor,
				};

	return (
		<button
			className={`
			  flex 
        justify-center
        items-center
        rounded-4xl
        text-lg
        cursor-pointer
        ${classes.button}
        ${className}
        disabled:opacity-50 disabled:cursor-not-allowed
      `}
			style={{ ...styles, ...style } as CSSProperties}
			onClick={(e) => {
				e.preventDefault();
				onClick && onClick(e);
			}}
			{...props}
		>
			{icon}
		</button>
	);
}
