import { ButtonHTMLAttributes, forwardRef, ReactNode } from 'react';
import classes from './Button.module.css';
import { Loading } from '../../modules/commons/components/Loading';
import { useTheme } from '../../theme/components/QuestioryThemeProvider/QuestioryThemeProvider';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
	children?: ReactNode;
	isLoading?: boolean;
	fontSize?: string;
	display?: string;
	rightIcon?: ReactNode;
	leftIcon?: ReactNode;
	variant?: 'primary' | 'invert';
}

const Button = forwardRef<HTMLButtonElement, Props>(
	({ children, leftIcon, rightIcon, className, style, onClick, isLoading = false, fontSize = 'text-base', display = 'inline-flex', variant = 'primary', ...props }, ref) => {
		const { colors } = useTheme();
		const styles =
			variant === 'invert'
				? {
						'--background-color': colors.buttonTextColor,
						'--text-color': colors.buttonColor,
						border: 'none',
						display,
					}
				: {
						'--background-color': colors.buttonColor,
						'--text-color': colors.buttonTextColor,
						display,
					};

		return (
			<button
				ref={ref}
				className={`
				px-4
        justify-center
        rounded-4xl
        font-semibold
        cursor-pointer
        ${classes.button}
        ${className}
      `}
				style={{ ...styles, ...style }}
				onClick={(e) => {
					e.preventDefault();
					onClick && onClick(e);
				}}
				{...props}
			>
				<div className='flex gap-2 items-center'>
					{isLoading ? (
						<Loading />
					) : (
						<>
							{leftIcon && <span className={`${fontSize}`}>{leftIcon}</span>}
							{children && <span className={`${fontSize}`}>{children}</span>}
							{rightIcon && <span className={`${fontSize} `}>{rightIcon}</span>}
						</>
					)}
				</div>
			</button>
		);
	}
);

Button.displayName = 'Button';

export { Button };
