import { CSSProperties, forwardRef, InputHTMLAttributes } from 'react';
import classes from './Input.module.css';
import { useTheme } from '../../theme/components/QuestioryThemeProvider/QuestioryThemeProvider.tsx';

interface Props extends InputHTMLAttributes<HTMLInputElement> {}

const Input = forwardRef<HTMLInputElement, Props>(({ className, style, ...props }, ref) => {
	const { colors } = useTheme();

	return (
		<input
			ref={ref}
			style={
				{
					'--placeholder-color': colors.placeholderColor,
					'--background-color': colors.inputColor,
					'--text-color': colors.textColor,
					'--border-color': colors.brand,
					...style,
				} as CSSProperties
			}
			className={`
					w-full
          h-12
          rounded-3xl
          outline-none
          border-2
          px-4
          ${classes.input}
          ${className}
          disabled:opacity-50 disabled:cursor-not-allowed
        `}
			{...props}
		/>
	);
});

Input.displayName = 'Input';

export { Input };
