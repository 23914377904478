import React, { useState } from 'react';
import { Loading } from '../../../commons/components/Loading';
import isEmpty from 'lodash/isEmpty';
import { motion, Variants } from 'framer-motion';
import { BasicCardDataMedia, CardaDataMediaType, CardData } from '../../service/VisualizationService.ts';
import { useCardsData } from '../../hooks/useCardsData.ts';
import { VisualizationWrapper } from '../VisualizationWrapper';
import { VisualizationProps } from '../Visualization/Visualization.tsx';
import { BasicCardsVisualizationT } from '../../model/Visualization.ts';
import classes from './BasicFlipCardsVisualization.module.css';
import { useTheme } from '../../../../theme/components/QuestioryThemeProvider/QuestioryThemeProvider.tsx';

export const BasicFlipCardsVisualization: React.FC<VisualizationProps> = ({ presentationId, visualization }) => {
	const conf = visualization.configuration as BasicCardsVisualizationT;
	const { data, isLoading } = useCardsData(presentationId, visualization.id);

	if (isLoading) {
		return <Loading />;
	}

	return (
		<VisualizationWrapper visualization={visualization} isNoData={!isLoading && isEmpty(data)}>
			<div className={`grid gap-2 mx-16 my-8 justify-items-center grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-${conf.maxColumns}`}>
				{data?.map((entry) => <ImageFlipCard key={entry.title} entry={entry} />)}
			</div>
		</VisualizationWrapper>
	);
};

interface ImageFlipCardProps {
	entry: CardData;
}

const variants: Variants = {
	front: { rotateY: 0 },
	back: { rotateY: 180 },
};

const ImageFlipCard = ({ entry }: ImageFlipCardProps) => {
	const emptyDescription = isEmpty(entry.description);
	const [isFlipped, setIsFlipped] = useState(false);
	const { colors } = useTheme();

	const media = entry.media as BasicCardDataMedia;
	const handleOnClick = () => {
		setIsFlipped(!isFlipped);
	};

	const renderFlipped = () => (
		<div className='m-auto'>
			{!emptyDescription ? (
				<p className={`text-xl text-center break-words m-auto  ${classes.rotateY180}`}>{entry.description}</p>
			) : (
				<h3 className={`text-2xl text-center break-words font-bold my-2 ${classes.rotateY180}`}>{entry.title}</h3>
			)}
		</div>
	);

	const renderNotFlipped = () => (
		<div className='flex flex-col m-auto overflow-y-auto text-center p-4'>
			<>
				{/* Image Media */}
				{media.mediaType === CardaDataMediaType.IMAGE && <img src={media.value} alt={entry.title} className='w-full h-full object-cover rounded-2xl mb-2' />}

				{/* Icon Media */}
				{media.mediaType === CardaDataMediaType.ICON && (
					<div className='flex justify-center p-4'>
						<div
							className={`flex items-center justify-center rounded-full 
                        ${emptyDescription ? 'w-24 h-24 text-6xl' : 'w-16 h-16 text-4xl'} mr-2`}
							style={{ backgroundColor: colors.highlight }}
						>
							{media.value}
						</div>
					</div>
				)}

				{/* Drawing Media */}
				{media.mediaType === CardaDataMediaType.DRAWING && (
					<img src={`data:image/svg+xml;base64,${btoa(decodeURIComponent(encodeURIComponent(media.value)))}`} alt='SVG Content' className='w-full h-full mb-3' />
				)}
			</>

			{/* Title (if description is not empty) */}
			{!emptyDescription && (
				<div className='flex justify-center'>
					<h3 className='break-words text-center text-2xl font-bold my-0' style={{ color: colors.cardTextColor }}>
						{entry.title}
					</h3>
				</div>
			)}
		</div>
	);

	return (
		<motion.div
			animate={!isFlipped ? 'front' : 'back'}
			onClick={handleOnClick}
			variants={variants}
			transition={{
				duration: 1 / 2,
			}}
			whileHover={{
				scale: 1.07,
				transition: { duration: 1 / 5 },
				zIndex: 1,
			}}
			style={{ margin: '0.5rem' }}
		>
			<div
				id='card'
				onClick={handleOnClick}
				className='w-64 h-64 flex justify-center m-auto p-4 shadow-md cursor-pointer rounded-3xl transition-shadow duration-200 hover:shadow-lg overflow-y-auto'
				style={{ backgroundColor: colors.cardBackgroundColor }}
			>
				{isFlipped ? renderFlipped() : renderNotFlipped()}
			</div>
		</motion.div>
	);
};
