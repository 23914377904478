import React, { useEffect } from 'react';
import { CategorisedConfigurationCategoryItem, CategorisedInteractionConfigurationT, InteractionT } from '../../../../model/Interaction.ts';
import { Control, useFieldArray, UseFormRegister, useWatch } from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';
import { MdClose } from 'react-icons/md';
import { FiPlus } from 'react-icons/fi';
import { Entry } from '../../CategorisedInteraction.tsx';
import { validateMaxLength } from '../../../../validation/InteractionValidation.ts';
import { translate } from '../../../../../../translate';
import { IconButton } from '../../../../../../components/IconButton';
import { Input } from '../../../../../../components/Input';
import { Button } from '../../../../../../components/Button';
import { useTheme } from '../../../../../../theme/components/QuestioryThemeProvider/QuestioryThemeProvider.tsx';

interface Props {
	interaction: InteractionT;
	categoryEntry: CategorisedConfigurationCategoryItem;
	register: UseFormRegister<Entry>;
	control: Control<Entry>;
	disabled: boolean;
}

export const CategorisedCategory: React.FC<Props> = ({ interaction, categoryEntry, register, control, disabled }) => {
	const categorisedConfig = interaction.configuration as CategorisedInteractionConfigurationT;
	const { colors } = useTheme();
	const { fields, append, remove } = useFieldArray({
		name: `entry.[${categoryEntry.id}]`,
		control,
		rules: {
			minLength: 1,
			maxLength: categorisedConfig.maximumAnswers,
			// required: interaction.required,
		},
	});

	const watchEntry = useWatch({
		control,
		name: `entry.[${categoryEntry.id}]`,
	});

	const canAdd = fields.length < categorisedConfig.maximumAnswers;
	const canRemove = fields.length > 1;

	useEffect(() => {
		if (isEmpty(fields)) {
			append({ value: '' }, { shouldFocus: false });
		}
	}, [append, fields]);

	const handleAdd = () => {
		if (canAdd) {
			append(
				{ value: '' },
				{
					focusName: `entry.[${categoryEntry.id}].${fields.length}.value`,
					shouldFocus: false,
				}
			);
		}
	};

	const handleRemove = (index: number) => () => {
		if (canRemove) {
			remove(index);
		}
	};

	return (
		<>
			<div className='flex flex-col my-4 gap-3'>
				{fields.map((field, index) => (
					<div key={field.id} className='flex flex-col w-full relative'>
						<Input
							id={field.id}
							autoComplete='off'
							className='pe-11'
							disabled={disabled}
							{...register(`entry.[${categoryEntry.id}].${index}.value`, {
								onChange: () => {
									const emptyField = watchEntry.filter((value) => value.value === '').length > 0;
									if (!emptyField && canAdd) {
										handleAdd();
									}
								},
								validate: {
									maxLength: (value) => validateMaxLength(value, 500, translate('Field too long')),
								},
							})}
						/>
						{index !== 0 && (
							<Button
								className='absolute right-2 top-1/2 -translate-y-1/2 bg-transparent! p-2'
								onClick={handleRemove(index)}
								aria-label='Delete'
								rightIcon={<MdClose style={{ color: colors.brand }} className='w-5 h-5' />}
							/>
						)}
					</div>
				))}
			</div>
			{canAdd && (
				<div className='flex justify-end px-3 py-0 my-0'>
					<IconButton style={{ backgroundColor: categoryEntry.color }} aria-label='Add' disabled={disabled} onClick={handleAdd} icon={<FiPlus />} />
				</div>
			)}
		</>
	);
};
