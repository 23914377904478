import React from 'react';
import { SimpleMathConfiguration } from '../../model/Visualization.ts';
import { useSimpleMathEntries } from '../../hooks/useNumberData.ts';
import { Loading } from '../../../commons/components/Loading';
import ceil from 'lodash/ceil';
import { VisualizationWrapper } from '../VisualizationWrapper';
import { VisualizationProps } from '../Visualization/Visualization.tsx';

export const AverageVisualization: React.FC<VisualizationProps> = ({ presentationId, visualization }) => {
	const conf = visualization.configuration as SimpleMathConfiguration;
	const { data, isLoading } = useSimpleMathEntries(presentationId, visualization.id);

	if (isLoading) {
		return <Loading />;
	}

	const noData = !isLoading && (!data || !data.value);
	return (
		<VisualizationWrapper visualization={visualization} isNoData={noData}>
			{!noData ? (
				<div className='flex flex-col gap-2 items-center justify-center text-2xl'>
					<p className='text-6xl'>{ceil(data!.value).toLocaleString()}</p>
					<p className='text-md mx-16 text-center break-words'>{conf.reference}</p>
				</div>
			) : null}
		</VisualizationWrapper>
	);
};
