import { ContentConfigurationStatementT, StaticContentT } from '../../model/StaticContent.ts';
import { Title } from '../../../commons/components/Title';

interface Props {
	content: StaticContentT;
}

export function ContentStatement({ content }: Props) {
	const conf = content.configuration as ContentConfigurationStatementT;
	return (
		<div id='statement-wrapper' className='m-auto w-[90%] xl:w-[80%] p-4'>
			<Title title={content.title} description={content.description} />
			<p className='text-xl sm:text-3xl font-light whitespace-pre-wrap'>{conf.statement}</p>
		</div>
	);
}
