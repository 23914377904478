import React from 'react';
import { Title } from '../../../commons/components/Title';
import { InteractionT } from '../../model/Interaction.ts';
import isEmpty from 'lodash/isEmpty';
import { SlideArrows } from '../../../presentation/components/SlideArrows';
import { translate } from '../../../../translate';

interface Props {
	interaction: InteractionT;
	isSubmitDisabled?: boolean;
	onSubmit: (e?: React.BaseSyntheticEvent<object, unknown, unknown>) => Promise<void>;
	children: React.ReactNode;
}

const HONEY_POT_FIELD_NAME = 'countryReference';

export const InteractionWrapper: React.FC<Props> = ({ interaction, isSubmitDisabled = false, onSubmit, children }) => {
	const handleOnSubmit = (e?: React.BaseSyntheticEvent<object, unknown, unknown>) => {
		const honeyPotValue = document.getElementById(HONEY_POT_FIELD_NAME) as HTMLInputElement | null;
		if (isEmpty(honeyPotValue?.value)) {
			onSubmit(e);
		}
	};
	return (
		<>
			<section className='flex h-[93%] overflow-y-auto'>
				<form id={interaction.id} onSubmit={handleOnSubmit} className='m-auto w-[90%] xl:w-[80%] p-4'>
					<Title title={interaction.title} description={interaction.description} />
					<input id={HONEY_POT_FIELD_NAME} name={HONEY_POT_FIELD_NAME} type='text' style={{ display: 'none' }} />
					{children}
					<SlideArrows onSubmit={handleOnSubmit} canSkip={!interaction.required} isSubmitDisabled={isSubmitDisabled} submitText={translate('Submit')} />
				</form>
			</section>
		</>
	);
};
