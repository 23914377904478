import { MultipleChoiceConfigurationT } from '../../model/Interaction.ts';
import { InteractionProps } from '../Interaction/Interaction.tsx';
import { SingleResponse } from './_/SingleResponse';
import { MultipleResponse } from './_/MultipleResponse';

export const MultipleChoiceInteraction = ({ presentationId, interaction, isDisabled, onInteractionEnd }: InteractionProps) => {
	const config = interaction.configuration as MultipleChoiceConfigurationT;
	if (config.multipleAnswer) {
		return <MultipleResponse presentationId={presentationId} interaction={interaction} isDisabled={isDisabled} onInteractionEnd={onInteractionEnd} />;
	} else if (!config.multipleAnswer) {
		return <SingleResponse presentationId={presentationId} interaction={interaction} isDisabled={isDisabled} onInteractionEnd={onInteractionEnd} />;
	} else {
		return null;
	}
};
