import React from 'react';
import { useCardsData } from '../../hooks/useCardsData.ts';
import { Loading } from '../../../commons/components/Loading';
import isEmpty from 'lodash/isEmpty';
import { Masonry } from 'react-plock';
import { BasicCardDataMedia, CardaDataMediaType, CardData, LinkPreviewDataMedia } from '../../service/VisualizationService.ts';
import { VisualizationWrapper } from '../VisualizationWrapper';
import { VisualizationProps } from '../Visualization/Visualization.tsx';
import { BasicCardsVisualizationT } from '../../model/Visualization.ts';
import { useTheme } from '../../../../theme/components/QuestioryThemeProvider/QuestioryThemeProvider.tsx';

export const BasicCardsVisualization: React.FC<VisualizationProps> = ({ presentationId, visualization }) => {
	const conf = visualization.configuration as BasicCardsVisualizationT;
	const { data, isLoading } = useCardsData(presentationId, visualization.id);

	if (isLoading) {
		return <Loading />;
	}

	return (
		<VisualizationWrapper visualization={visualization} isNoData={!isLoading && isEmpty(data)}>
			<div className='max-h-[70%] overflow-y-auto mx-16 mb-8'>
				<Masonry
					items={data || []}
					config={{
						columns: [1, 2, 3, conf.maxColumns],
						media: [640, 1000, 1152, 1280],
						gap: [12, 12, 12, 24],
					}}
					render={(entry) => (
						<React.Fragment key={entry.title}>
							{entry.media && entry.media.mediaType === CardaDataMediaType.LINK ? (
								<LinkPreviewCard cardData={entry} hideCardTitle={conf.hideCardTitle} />
							) : (
								<BasicCard cardData={entry} hideCardTitle={conf.hideCardTitle} />
							)}
						</React.Fragment>
					)}
				/>
			</div>
		</VisualizationWrapper>
	);
};

interface CardProps {
	cardData: CardData;
	hideCardTitle: boolean;
}

const BasicCard = ({ cardData, hideCardTitle }: CardProps) => {
	const { colors } = useTheme();
	const media = cardData.media as BasicCardDataMedia | undefined;
	return (
		<div className='mb-2 shadow-lg rounded-3xl overflow-hidden' style={{ backgroundColor: colors.cardBackgroundColor }}>
			{/* Image Media */}
			{media?.mediaType === 'IMAGE' && (
				<div className='p-0'>
					<img src={media.value} alt={cardData.title} className='w-full h-full object-cover' />
				</div>
			)}

			{/* Icon Media */}
			{media?.mediaType === 'ICON' && (
				<div className='flex justify-start items-center p-4'>
					<div className='w-16 h-16 rounded-full flex items-center justify-center mr-2' style={{ backgroundColor: colors.highlight }}>
						<p className='flex text-4xl'>{media.value}</p>
					</div>
				</div>
			)}

			{/* Drawing Media */}
			{media?.mediaType === 'DRAWING' && (
				<div className='p-4'>
					<img src={`data:image/svg+xml;base64,${btoa(decodeURIComponent(encodeURIComponent(media.value)))}`} alt='SVG Content' className='w-full h-full' />
				</div>
			)}

			{/* Card Body */}
			<div className='p-4 whitespace-pre-wrap'>
				<div className='flex items-center'>
					<h3 className='break-words my-2 text-2xl font-bold' style={{ color: colors.cardTextColor }}>
						{!hideCardTitle ? cardData.title : cardData.title.replace(/./g, '  ')}
					</h3>
				</div>
				<p className='text-2xl'>{cardData.description}</p>
			</div>
		</div>
	);
};

const LinkPreviewCard = ({ cardData, hideCardTitle }: CardProps) => {
	const media = cardData.media as LinkPreviewDataMedia;
	const { colors } = useTheme();
	return (
		<a
			id='link-box'
			href={ensureProtocol(media.link)}
			target={media.openInNewTab ? '_blank' : '_self'}
			rel='noreferrer noopener'
			className='block mb-2 shadow-lg rounded-3xl overflow-hidden hover:shadow-xl transition-shadow duration-200'
			style={{ backgroundColor: colors.cardBackgroundColor }}
		>
			{/* Image Media */}
			{!isEmpty(media.imageUrl) && (
				<div className='p-0'>
					<img src={media.imageUrl} alt={cardData.title} className='w-full h-full object-cover' />
				</div>
			)}

			{/* Card Body */}
			<div className='p-4 whitespace-pre-wrap decoration-0' style={{ color: colors.cardTextColor }}>
				<div className='flex items-center'>{!hideCardTitle && <h3 className='break-words my-2 text-2xl font-bold'>{cardData.title}</h3>}</div>
				<p className='text-2xl'>{cardData.description}</p>
			</div>
		</a>
	);
};

function ensureProtocol(url: string): string {
	// Check if the URL starts with 'http://' or 'https://'
	if (!url.startsWith('http://') && !url.startsWith('https://')) {
		// Prepend '//' to the URL if it does not start with any of the above
		return '//' + url;
	}
	// Return the URL as is if it already contains a protocol
	return url;
}
