import { LuMessagesSquare } from 'react-icons/lu';
import { translate } from '../../../../translate';

export function NoDataVisualizationMessage() {
	return (
		<div className='flex gap-2 justify-center items-center'>
			<LuMessagesSquare className='text-xl sm:text-2xl text-gray-700' />
			<h1 className='text-xl sm:text-2xl mt-0 text-center text-gray-700!'>{translate('It seems there are no answers to this question yet.')}</h1>
		</div>
	);
}
