import ApiClient from '../../../apiClient';

// visuzliation data
export const rotatingWordsData = async (presentationId: string, visualizationId: string): Promise<string[]> => {
	return (await ApiClient.getInstance().get(`/client-app/presentations/${presentationId}/visualizations/${visualizationId}/rotating-words`)).data;
};

export interface SimpleMathEntry {
	value: number;
}

export const getSimpleMathEntries = async (presentationId: string, visualizationId: string): Promise<SimpleMathEntry> => {
	return (await ApiClient.getInstance().get(`/client-app/presentations/${presentationId}/visualizations/${visualizationId}/simple-math`)).data;
};

// ---
export interface CardData {
	title: string;
	description?: string;
	media?: BasicCardDataMedia | LinkPreviewDataMedia;
}

export interface BasicCardDataMedia {
	value: string;
	mediaType: CardaDataMediaType;
}

export interface LinkPreviewDataMedia {
	link: string;
	imageUrl?: string;
	openInNewTab: boolean;
	mediaType: CardaDataMediaType;
}

export enum CardaDataMediaType {
	IMAGE = 'IMAGE',
	ICON = 'ICON',
	DRAWING = 'DRAWING',
	LINK = 'LINK',
}

export const getCardsData = async (presentationId: string, visualizationId: string): Promise<CardData[]> => {
	return (await ApiClient.getInstance().get(`/client-app/presentations/${presentationId}/visualizations/${visualizationId}/cards`)).data;
};

// ---

interface BarChartData {
	barChartDataList: BarChartDataItem[];
	maxValue: number;
}

interface BarChartDataItem {
	category: string;
	value: number;
	color: string;
}

export const getBarChartData = async (presentationId: string, visualizationId: string): Promise<BarChartData> => {
	return (await ApiClient.getInstance().get(`/client-app/presentations/${presentationId}/visualizations/${visualizationId}/bar-chart`)).data;
};

// ---

export const getPieChartData = async (presentationId: string, visualizationId: string): Promise<BarChartData> => {
	return (await ApiClient.getInstance().get(`/client-app/presentations/${presentationId}/visualizations/${visualizationId}/pie-chart`)).data;
};

// ---

export interface WordCloudData {
	category: string;
	value: number;
	color: string;
}

export const getWordCloudData = async (presentationId: string, visualizationId: string): Promise<WordCloudData[]> => {
	return (await ApiClient.getInstance().get(`/client-app/presentations/${presentationId}/visualizations/${visualizationId}/word-cloud`)).data;
};

export const getSentenceCloudData = async (presentationId: string, visualizationId: string): Promise<WordCloudData[]> => {
	return (await ApiClient.getInstance().get(`/client-app/presentations/${presentationId}/visualizations/${visualizationId}/sentence-cloud`)).data;
};

// ---
export interface WheelEntry {
	key: string;
	entries: string[];
	color: string;
}

export const getWheelData = async (presentationId: string, visualizationId: string): Promise<WheelEntry[]> => {
	return (await ApiClient.getInstance().get(`/client-app/presentations/${presentationId}/visualizations/${visualizationId}/wheel`)).data;
};

// ---
interface NodesEntry {
	key: string;
	entries: string[];
	color: string;
}

export const getNodesData = async (presentationId: string, visualizationId: string): Promise<NodesEntry[]> => {
	return (await ApiClient.getInstance().get(`/client-app/presentations/${presentationId}/visualizations/${visualizationId}/nodes`)).data;
};

// ---
export interface BlocksEntry {
	key: string;
	entries: string[];
	color: string;
}

export const getBlocksData = async (presentationId: string, visualizationId: string): Promise<BlocksEntry[]> => {
	return (await ApiClient.getInstance().get(`/client-app/presentations/${presentationId}/visualizations/${visualizationId}/blocks`)).data;
};

// ---

interface GaugeData {
	minValue: number;
	maxValue: number;
	entries: GaugeEntry[];
}

interface GaugeEntry {
	key: string;
	entry: number;
	color: string;
}

export const getGaugeData = async (presentationId: string, visualizationId: string): Promise<GaugeData> => {
	return (await ApiClient.getInstance().get(`/client-app/presentations/${presentationId}/visualizations/${visualizationId}/gauge`)).data;
};

// ---
interface ComicData {
	entries: ComicDataEntry[];
}

export interface ComicDataEntry {
	heading: string;
	text: string;
	drawing: string;
}

export const getComicData = async (presentationId: string, visualizationId: string): Promise<ComicData> => {
	return (await ApiClient.getInstance().get(`/client-app/presentations/${presentationId}/visualizations/${visualizationId}/comic`)).data;
};

// ---
interface BoardDataItem {
	key: string;
	color: string;
	entries: string[];
}

export const getBoardData = async (presentationId: string, visualizationId: string): Promise<BoardDataItem[]> => {
	return (await ApiClient.getInstance().get(`/client-app/presentations/${presentationId}/visualizations/${visualizationId}/board`)).data;
};

// ---
interface PillsDataItem {
	key: string;
	color: string;
	entries: string[];
}

export const getPillsData = async (presentationId: string, visualizationId: string): Promise<PillsDataItem[]> => {
	return (await ApiClient.getInstance().get(`/client-app/presentations/${presentationId}/visualizations/${visualizationId}/pills`)).data;
};
