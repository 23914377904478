interface ColorScheme {
	color50: string;
	color100: string;
	color200: string;
	color300: string;
	color400: string;
	color500: string;
	color600: string;
	color700: string;
	color800: string;
	color900: string;
}

interface Colors {
	brand: string;
	highlight: string;
	inputColor: string;
	textColor: string;
	background: string;
	success: string;
	light: string;
	placeholderColor: string;
	cardBackgroundColor: string;
	cardTextColor: string;
	buttonColor: string;
	buttonTextColor: string;
	colorScheme: ColorScheme;
}

interface ThemeFonts {
	heading: string;
	body: string;
	armCharts: string;
	comics: string;
}

export interface PlayerTheme {
	colors: Colors;
	fonts: ThemeFonts;
}

const DEFAULT_FONT = 'Montserrat';

export const DEFAULT_THEME: PlayerTheme = {
	fonts: {
		heading: DEFAULT_FONT,
		body: DEFAULT_FONT,
		armCharts: DEFAULT_FONT,
		comics: 'Comic Neue',
	},
	colors: {
		brand: '#000000',
		highlight: '#EDEDED',
		inputColor: '#ffffff',
		textColor: '#000000',
		background: '#ffffff',
		success: '#748F4F',
		light: '#F8F9FA',
		cardBackgroundColor: '#FFFFFF',
		cardTextColor: '#000000',
		buttonColor: '#000000',
		buttonTextColor: '#FFFFFF',
		placeholderColor: '#71797e',
		colorScheme: {
			color50: '#BD5D58',
			color100: '#DA9A5C',
			color200: '#E2C265',
			color300: '#9C9E4C',
			color400: '#748F4F',
			color500: '#4B7F52',
			color600: '#338B81',
			color700: '#1B96B0',
			color800: '#6B97AB',
			color900: '#897A91',
		},
	},
};
