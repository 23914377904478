import isEmpty from 'lodash/isEmpty';
import React, { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { usePreviewPageInfo } from '../../../../pages/PreviewPage/PreviewPage.tsx';
import { saveNumberEntry } from '../../service/EntryService.ts';
import { NumberInteractionConfigurationT } from '../../model/Interaction.ts';
import { InteractionWrapper } from '../InteractionWrapper';
import { InteractionContainer } from '../InteractionContainer';
import { InteractionProps } from '../Interaction/Interaction.tsx';
import { validateRequired } from '../../validation/InteractionValidation.ts';
import { hasSubmission, recordSubmission } from '../../service/InteractionSubmissionTracker.ts';
import { translate } from '../../../../translate';
import { AlertBox } from '../../../commons/components/AlertBox';
import { Input } from '../../../../components/Input';

interface FormValueType {
	entry: number;
}

export const NumberInteraction: React.FC<InteractionProps> = ({ presentationId, interaction, isDisabled, onInteractionEnd }) => {
	const config = interaction.configuration as NumberInteractionConfigurationT;
	const required = true; //config.required;
	const alreadySubmitted = hasSubmission(interaction.id);
	const {
		register,
		handleSubmit,
		formState: { isSubmitting, errors },
		setFocus,
		reset,
	} = useForm<FormValueType>();

	const { shouldAutoFocus } = usePreviewPageInfo();

	useEffect(() => {
		if (shouldAutoFocus) {
			setFocus('entry');
		}
	}, [setFocus, shouldAutoFocus]);

	const onSubmit: SubmitHandler<FormValueType> = async (data) => {
		if (!isEmpty(data.entry)) {
			await saveNumberEntry(presentationId, interaction.id, data.entry);
			recordSubmission(interaction.id);
			reset();
			onInteractionEnd();
		} else if (alreadySubmitted || !required) {
			onInteractionEnd();
		}
	};
	return (
		<InteractionWrapper interaction={interaction} onSubmit={handleSubmit(onSubmit)} isSubmitDisabled={isSubmitting || isDisabled}>
			<InteractionContainer>
				<div className='flex flex-col gap-2 p-2'>
					{errors.entry && <AlertBox title={errors.entry.message} />}
					<Input
						type='number'
						min={config.minValue}
						max={config.maxValue}
						placeholder={config.placeholder}
						autoComplete='off'
						inputMode='numeric'
						disabled={isDisabled}
						{...register('entry', {
							validate: {
								required: (value) => {
									return required && !alreadySubmitted ? validateRequired('' + value, translate('Number field is required')) : undefined;
								},
								min: (value) => (!isEmpty(value) && +value < config.minValue ? `Lowest allowed number: ${config.minValue}` : undefined),
								max: (value) => (!isEmpty(value) && +value > config.maxValue ? `Highest allowed number ${config.maxValue}` : undefined),
							},
						})}
					/>
				</div>
			</InteractionContainer>
		</InteractionWrapper>
	);
};
