import React, { useEffect, useState } from 'react';
import { useUnsplashSearch } from '../../querHooks/useUnsplashSearch';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';
import { unsplashClient, UnsplashPhoto, UnsplashSearchParams } from '../../api/UnsplashApi';
import { Loading } from '../../../commons/components/Loading';
import { AlertBox } from '../../../commons/components/AlertBox';
import { Input } from '../../../../components/Input';

interface Props {
	onChange: (selectedImageUrl: UnsplashPhoto) => void;
}

export const UnsplashImagePicker: React.FC<Props> = ({ onChange }) => {
	const [query, setQuery] = useState<UnsplashSearchParams>({
		page: 1,
		query: '',
	});

	const { isLoading, data, isError } = useUnsplashSearch(query);
	const [hoveredPhotoId, setHoveredPhotoId] = useState<string | null>(null);

	const handleClick = (photo: UnsplashPhoto) => async () => {
		onChange(photo);
		await unsplashClient.trackPhotoDownload(photo);
	};

	// const handleGoBack = () => {
	// 	if (query.page > 1) {
	// 		setQuery({ ...query, page: query.page - 1 });
	// 	}
	// };
	//
	// const hadleGoForward = () => {
	// 	if (data && query.page <= data.total_pages) {
	// 		setQuery({ ...query, page: query.page + 1 });
	// 	}
	// };

	const handleOnInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setQuery({ ...query, page: 1, query: event.target.value });
	};

	const debouncedInputChangeHandler = debounce(handleOnInputChange, 1200);

	useEffect(() => {
		return () => {
			debouncedInputChangeHandler.cancel();
		};
	}, []);

	if (isError) {
		return <AlertBox title='Error fetching from Unsplash' />;
	}

	return (
		<div className='flex flex-col gap-4'>
			<Input placeholder='Search for an Image' onChange={debouncedInputChangeHandler} />
			{isLoading ? <Loading /> : null}
			{data && !isEmpty(data?.results) ? (
				<>
					<div className='grid grid-cols-2 md:grid-cols-4 gap-8'>
						{data.results.map((photo) => (
							<div key={photo.id} className='relative p-2' onMouseEnter={() => setHoveredPhotoId(photo.id)} onMouseLeave={() => setHoveredPhotoId(null)}>
								<img src={photo.urls.small} alt={photo.description ?? photo.user.name} onClick={handleClick(photo)} className='cursor-pointer' />
								{hoveredPhotoId === photo.id && (
									<p className='m-4 text-sm text-white absolute bottom-1 left-1 bg-black bg-opacity-80 p-2 rounded'>
										<a className='text-white' href={`${photo.user.links.html}?utm_source=questiory&utm_medium=referral`} target='_blank' rel='nofollow noreferrer noopener'>
											Photo by: {photo.user.name}
										</a>{' '}
										on{' '}
										<a className='text-white' href='https://unsplash.com?utm_source=questiory&utm_medium=referral' target='_blank' rel='nofollow noreferrer noopener'>
											Unsplash
										</a>
									</p>
								)}
							</div>
						))}
					</div>
				</>
			) : null}
		</div>
	);
};
