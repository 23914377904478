import { ElementT } from '../../presentation/model/Presentation.ts';

export interface VisualizationT extends ElementT {
	type: VisualizationTypeT;
	configuration: VisualizationConfigurationT;
}

export interface VisualizationConfigurationT {
	id: number;
	type: VisualizationTypeT;
}

export interface WordCloudConfiguration extends VisualizationConfigurationT {
	selectedAnswers: string[];
	reference: string;
}

export interface BarChartConfiguration extends VisualizationConfigurationT {
	selectedAnswers: string[];
	reference: string;
}

export interface WheelConfiguration extends VisualizationConfigurationT {
	chartTitle?: string;
}

export interface BlocksConfiguration extends VisualizationConfigurationT {
	chartTitle?: string;
}

export interface PictogramConfiguration extends VisualizationConfigurationT {
	icon: string;
}

export interface PieChartConfiguration extends VisualizationConfigurationT {
	selectedAnswers: string[];
	reference: string;
	variant: 'PIE' | 'DONUT';
	showFloatingLegend: boolean;
	showFooterLegend: boolean;
}

export interface SimpleMathConfiguration extends VisualizationConfigurationT {
	operation: 'SUM' | 'AVG';
	reference: string;
}

export interface BasicCardsVisualizationT extends VisualizationConfigurationT {
	maxColumns: number;
	hideCardTitle: boolean;
}

export enum VisualizationTypeT {
	COMIC = 'COMIC',
	BAR_CHART = 'BAR_CHART',
	PIE_CHART = 'PIE_CHART',
	WORD_CLOUD = 'WORD_CLOUD',
	SENTENCE_CLOUD = 'SENTENCE_CLOUD',
	ROTATING_WORDS = 'ROTATING_WORDS',
	SIMPLE_MATH = 'SIMPLE_MATH',
	WHEEL = 'WHEEL',
	BLOCKS = 'BLOCKS',
	NODES = 'NODES',
	GAUGE = 'GAUGE',
	RADAR = 'RADAR',
	PICTOGRAM = 'PICTOGRAM',
	BASIC_CARDS = 'BASIC_CARDS',
	BASIC_FLIP_CARDS = 'BASIC_FLIP_CARDS',
	BOARD = 'BOARD',
	PILLS = 'PILLS',
}
