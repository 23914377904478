import React, { useEffect, useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import * as am5xy from '@amcharts/amcharts5/xy';
import * as am5radar from '@amcharts/amcharts5/radar';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { useGaugeData } from '../../hooks/useGaugeData.ts';
import { am5 } from '../../../commons/armcharts/AmChartsSetup.ts';
import am5themes_Responsive from '@amcharts/amcharts5/themes/Responsive';
import ceil from 'lodash/ceil';
import { VisualizationChartWrapper } from '../VisualizationChartWrapper';
import { VisualizationProps } from '../Visualization/Visualization.tsx';
import { useTheme } from '../../../../theme/components/QuestioryThemeProvider/QuestioryThemeProvider.tsx';

export const RadarVisualization: React.FC<VisualizationProps> = ({ visualization, presentationId }) => {
	// const conf = visualization.configuration as RadarConfiguration;
	const { colors, fonts } = useTheme();
	const { data, isLoading } = useGaugeData(presentationId, visualization.id);

	const radarData = useMemo(() => {
		const hasData = !isLoading && !isEmpty(data);
		if (!hasData) {
			return [];
		}

		return data.entries.map((d) => {
			const color = !isEmpty(d.color) ? d.color : colors.brand;
			return {
				category: d.key,
				value: ceil(d.entry),
				columnSettings: {
					fill: am5.color(color),
				},
			};
		});
	}, [data, colors.brand, isLoading]); // Dependencies

	useEffect(() => {
		let root: am5.Root | undefined;

		if (!isEmpty(radarData) && !isEmpty(data)) {
			root = am5.Root.new('chartdiv');
			const responsiveTheme = am5themes_Responsive.new(root);
			responsiveTheme.addRule({
				// name: 'PictorialStackedSeries',
				relevant: function (width) {
					return width < am5themes_Responsive.L;
				},
				applying: function () {
					xRenderer.labels.template.setAll({
						forceHidden: true,
					});
				},
			});

			root.setThemes([am5themes_Animated.new(root), responsiveTheme]);

			// Create chart
			// https://www.amcharts.com/docs/v5/charts/radar-chart/
			const chart = root.container.children.push(
				am5radar.RadarChart.new(root, {
					height: am5.percent(100),
					radius: am5.percent(70),
					panX: false,
					panY: false,
					wheelX: 'none',
					wheelY: 'none',
				})
			);

			// Create axes and their renderers
			// https://www.amcharts.com/docs/v5/charts/radar-chart/#Adding_axes
			const xRenderer = am5radar.AxisRendererCircular.new(root, {});
			xRenderer.labels.template.setAll({
				radius: 10,
				textType: 'adjusted',
				oversizedBehavior: 'wrap',
				fill: am5.color(colors.textColor), // Set text color to white
				fontFamily: fonts.armCharts,
			});

			const xAxis = chart.xAxes.push(
				am5xy.CategoryAxis.new(root, {
					maxDeviation: 0,
					categoryField: 'category',
					renderer: xRenderer,
					tooltip: am5.Tooltip.new(root, {}),
				})
			);

			const yAxis = chart.yAxes.push(
				am5xy.ValueAxis.new(root, {
					min: data.minValue,
					max: data.maxValue,
					renderer: am5radar.AxisRendererRadial.new(root, {}),
				})
			);
			yAxis.get('renderer').labels.template.setAll({
				fill: am5.color(colors.textColor), // assuming colors.textColor is white
				fontFamily: fonts.armCharts,
			});
			yAxis.get('renderer').labels.template.set('forceHidden', true); //Hide the numbers behind

			// Create series
			// https://www.amcharts.com/docs/v5/charts/radar-chart/#Adding_series
			const series = chart.series.push(
				am5radar.RadarColumnSeries.new(root, {
					xAxis: xAxis,
					yAxis: yAxis,
					valueYField: 'value',
					categoryXField: 'category',
					fill: root.interfaceColors.get('alternativeBackground'),
				})
			);

			series.columns.template.setAll({
				tooltipText: `[fontFamily: ${fonts.armCharts}]{categoryX}: {valueY}[/]`,
				templateField: 'columnSettings',
				strokeOpacity: 0,
				width: am5.p100,
			});

			series.data.setAll(radarData);
			xAxis.data.setAll(radarData);

			// Animate chart
			// https://www.amcharts.com/docs/v5/concepts/animations/#Initial_animation
			series.appear(1000);
			chart.appear(1000, 100);
		}
		return () => {
			// Clean up on unmount
			root?.dispose();
		};
	}, [data, radarData]);

	return <VisualizationChartWrapper title={visualization.title} description={visualization.description} isLoading={isLoading} isNoData={isEmpty(radarData)} />;
};
