import React, { ChangeEvent, useState } from 'react';
import { translate } from '../../../../../../translate';
import { Input } from '../../../../../../components/Input';
import { Button } from '../../../../../../components/Button';

interface Props {
	onChange: (selectedImageUrl: string) => void;
}

export const UrlImagePicker: React.FC<Props> = ({ onChange }) => {
	const [imageUrl, setImageUrl] = useState('');
	const [error, setError] = useState('');

	const isValidImageUrl = (url: string) => {
		try {
			new URL(url);
			return true;
		} catch (_) {
			return false;
		}
	};

	const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
		const url = e.target.value;
		setImageUrl(url);

		if (isValidImageUrl(url)) {
			setError('');
		} else {
			setError(translate('Please enter a valid image url'));
		}
	};

	const handleSelectClick = () => {
		if (isValidImageUrl(imageUrl)) {
			onChange(imageUrl);
		} else {
			setError(translate('Please enter a valid image url'));
		}
	};

	return (
		<div className='flex flex-col items-center gap-4'>
			<Input type='url' placeholder='Type image link' autoComplete='off' value={imageUrl} onChange={handleInputChange} />
			{error && <p className='text-red-500 text-sm'>{error}</p>}
			{isValidImageUrl(imageUrl) && <img src={imageUrl} alt='Preview' className='w-64 h-64 object-contain mt-4' />}
			<Button disabled={!isValidImageUrl(imageUrl)} onClick={handleSelectClick}>
				Select
			</Button>
		</div>
	);
};
