import React, { useEffect, useState } from 'react';
import { FaLink, FaUnsplash } from 'react-icons/fa';
import { UnsplashImagePicker } from '../../../unsplash/components/UnsplashImagePicker';
import { UnsplashPhoto } from '../../../unsplash/api/UnsplashApi.ts';
import { UrlImagePicker } from './_/UrlImagePicker';

interface Props {
	isOpen: boolean;
	onClose: () => void;
	onChange: (selectedImageUrl: string) => void;
}

export const ImagePicker: React.FC<Props> = ({ isOpen, onClose, onChange }) => {
	const [activeTab, setActiveTab] = useState(0); // Track the active tab

	useEffect(() => {
		const handleKeyDown = (event: KeyboardEvent) => {
			if (event.key === 'Escape') {
				onClose();
			}
		};
		if (isOpen) {
			document.addEventListener('keydown', handleKeyDown);
		}
		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, [isOpen, onClose]);

	const handleOnUnsplashImage = (photo: UnsplashPhoto) => {
		onChange(photo.urls.regular);
		onClose();
	};

	const handleOnUrlImagePicker = (imageUrl: string) => {
		onChange(imageUrl);
		onClose();
	};

	return (
		<>
			{isOpen && (
				<div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50'>
					<div className='w-full h-full bg-white flex flex-col'>
						{/* Modal Header */}
						<div className='p-6 bg-white text-black text-xl flex justify-between items-center'>
							<span className='font-semibold'>Search image</span>
							<button onClick={onClose} className='text-black text-5xl'>
								&times;
							</button>
						</div>

						{/* Modal Body */}
						<div className='flex-1 p-6 bg-white'>
							{/* Tabs */}
							<div className='border-b border-gray-300 mb-4'>
								<div className='flex space-x-6'>
									<button
										onClick={(e) => {
											e.preventDefault();
											setActiveTab(0);
										}}
										className={`flex items-center text-lg py-2 border-b-2 transition ${
											activeTab === 0 ? 'border-black font-semibold' : 'border-transparent hover:border-gray-400'
										}`}
									>
										<FaUnsplash className='text-black' />
										<span className='text-black pl-2'>Unsplash Photo</span>
									</button>
									<button
										onClick={(e) => {
											e.preventDefault();
											setActiveTab(1);
										}}
										className={`flex items-center text-lg py-2 border-b-2 transition ${
											activeTab === 1 ? 'border-black font-semibold' : 'border-transparent hover:border-gray-400'
										}`}
									>
										<FaLink className='text-black' />
										<span className='text-black pl-2'>Self-hosted image</span>
									</button>
								</div>
							</div>

							{/* Tab Panels */}
							<div>
								{activeTab === 0 && <UnsplashImagePicker onChange={handleOnUnsplashImage} />}
								{activeTab === 1 && <UrlImagePicker onChange={handleOnUrlImagePicker} />}
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};
