import axios, { AxiosRequestConfig } from 'axios';

export default class ApiClient {
	private static instance: ApiClient;

	private tokenGenerator: () => Promise<string | undefined> = () => Promise.resolve(undefined);

	private readonly axiosClient;

	private constructor() {
		// console.log('windoooooow=================================================');
		// console.log('window.location.search', window.location.search);
		// console.log('window.location', window.location);
		// console.log('referrer', document.referrer);
		const calculatedUrl = window.location != window.parent.location ? document.referrer : document.location.href;
		// console.log('calculatedUrl', calculatedUrl);

		this.axiosClient = axios.create({
			baseURL: import.meta.env.VITE_API_BASE_URL,
			timeout: 30000,
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'X-Referrer': calculatedUrl,
				'X-API-KEY': import.meta.env.VITE_API_KEY,
			},
		});
		this.axiosClient.interceptors.request.use(
			async (config) => {
				try {
					const token = await this.tokenGenerator();
					if (token) config.headers['Authorization'] = `Bearer ${token}`;
				} catch (err) {
					console.error(err);
				}

				return config;
			},
			async (error) => Promise.reject(error)
		);
	}

	public static getInstance() {
		if (!ApiClient.instance) {
			ApiClient.instance = new ApiClient();
		}
		return ApiClient.instance;
	}

	withTokenGenerator(generator: () => Promise<string>) {
		this.tokenGenerator = generator;
		return this;
	}

	get(url: string, config?: AxiosRequestConfig) {
		return this.axiosClient.get(url, config);
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	post(url: string, data: any, config?: AxiosRequestConfig) {
		return this.axiosClient.post(url, data, config);
	}

	put(url: string, data: object, config?: AxiosRequestConfig) {
		return this.axiosClient.put(url, data, config);
	}

	delete(url: string, config?: AxiosRequestConfig) {
		return this.axiosClient.delete(url, config);
	}
}
