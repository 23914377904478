import isEmpty from 'lodash/isEmpty';
import React from 'react';

interface Props {
	reference?: string;
	circleColor?: string;
}

export const SelectedAnwserReference: React.FC<Props> = ({ reference = '', circleColor }) => {
	return !isEmpty(reference) ? (
		<div className='flex items-center justify-center m-8 text-xl'>
			{!isEmpty(circleColor) ? <div className='w-5 h-5 rounded-full mx-2' style={{ backgroundColor: circleColor }}></div> : null}
			<p>{reference}</p>
		</div>
	) : null;
};
