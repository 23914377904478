import React, { ChangeEvent, CSSProperties, useState } from 'react';
import classes from './CheckboxCard.module.css';
import { CheckIcon } from '../RadioCard/RadioCard.tsx';
import { useTheme } from '../../../../../../theme/components/QuestioryThemeProvider/QuestioryThemeProvider.tsx';

type CheckBoxInputProps = {
	option: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

export const CheckboxCard: React.FC<CheckBoxInputProps> = React.forwardRef<HTMLInputElement, CheckBoxInputProps>(({ option, ...props }, ref) => {
	const { colors } = useTheme();
	const [isChecked, setIsChecked] = useState(false);
	const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
		setIsChecked(e.target.checked);
		props.onChange && props.onChange(e);
	};
	return (
		<label
			//@ts-expect-error: TODO Fix types
			onChange={handleOnChange}
			style={
				{
					color: colors.cardTextColor,
					borderColor: 'transparent',
					'--background-color': colors.cardBackgroundColor,
					'--hover-background-color': colors.highlight,
				} as CSSProperties
			}
			className={`flex w-full min-w-[10rem] items-center text-center cursor-pointer gap-2 py-3 px-4 rounded-full border-2 border-transparent shadow-md transition-all ${classes.checkboxCard} ${props.disabled ? classes.disabled : ''}`}
		>
			<div className='flex-1'>
				<p className='font-light break-words text-lg md:text-xl lg:text-2xl'>{option}</p>
			</div>
			<input type='checkbox' {...props} ref={ref} value={option} hidden />
			<div
				className='w-7 h-7 flex items-center justify-center border-2 rounded-md transition-all'
				style={{
					borderColor: colors.brand,
					backgroundColor: isChecked ? colors.buttonColor : 'transparent',
					boxShadow: isChecked ? 'none' : 'inherit',
				}}
			>
				{isChecked && <CheckIcon />}
			</div>
		</label>
	);
});

CheckboxCard.displayName = 'CheckBoxInput'; // It's good practice to set a displayName when using forwardRef.
