import { ContentConfigurationEmbedT, StaticContentT } from '../../model/StaticContent.ts';
import { Title } from '../../../commons/components/Title';

interface Props {
	content: StaticContentT;
}

export function ContentEmbed({ content }: Props) {
	const conf = content.configuration as ContentConfigurationEmbedT;
	return (
		<div className='flex flex-col w-full h-full overflow-auto'>
			<Title title={content.title} description={content.description} />
			<iframe className='w-full h-full' src={conf.content} allowFullScreen></iframe>
		</div>
	);
}
