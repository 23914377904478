import React, { useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import { Navigate } from 'react-router';
import { hexToRGBA } from '../../../../util/ColorUtils.ts';
import { Element } from '../Element';
import { useSliderControls } from '../SliderControlsProvider/SliderControlsProvider.tsx';
import { useTheme } from '../../../../theme/components/QuestioryThemeProvider/QuestioryThemeProvider.tsx';

interface Props {}

export const Slide: React.FC<Props> = () => {
	const { colors } = useTheme();
	const slider = useSliderControls();

	useEffect(() => {
		//@ts-expect-error: problem with the type for current
		slider.refContainer?.current?.focus();
	}, [slider]);

	if (isEmpty(slider.currentSlide?.id)) {
		return <Navigate to={`/presentation/${slider.presentation.id}`} replace />;
	}

	if (slider.currentSlide && !isEmpty(slider.currentSlide?.id)) {
		const bgImage = !isEmpty(slider.currentSlide.imageBackgroundUrl)
			? `linear-gradient(${hexToRGBA(colors.background, 0.5)}, ${hexToRGBA(colors.background, 0.5)}), url(${slider.currentSlide.imageBackgroundUrl})`
			: undefined;
		return (
			<div id='background' className='h-svh bg-no-repeat bg-cover bg-center' style={{ backgroundColor: colors.background, backgroundImage: bgImage }}>
				{/* Slide Content */}
				<Element slide={slider.currentSlide} onInteractionEnd={slider.goForward} />
				{/* Progress Bar */}
				{!slider.presentation.hideProgressBar ? (
					<div
						id='progress'
						className='fixed top-0 left-0 h-1.5 transition-[width] duration-500 ease-out'
						style={{
							backgroundColor: colors.brand,
							width: `${(slider.currentSlideIndex / slider.totalSlides) * 100}%`,
						}}
					/>
				) : null}
				<a
					href='https://questiory.com?utm_source=player&utm_medium=referral'
					target='_blank'
					rel='noopener noreferrer'
					className={`fixed bottom-3 right-5 z-[100000] ${slider.presentation.hideQuestioryBrand ? 'hidden' : 'inline-block'}`}
				>
					<img src='/questiory_logo_inverted.png' alt='Logo' title='Questiory' className='w-[2.5rem] h-[2.5rem] lg:w-[2.8rem] lg:h-[2.8rem] rounded-full' />
				</a>
			</div>
		);
	}
};
