import React from 'react';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
	title?: string;
	status?: 'error' | 'success' | 'warning' | 'info';
}

export const AlertBox: React.FC<Props> = ({ title, status = 'error', ...props }) => {
	// Define status styles for the alert box
	const statusStyles: Record<string, string> = {
		error: 'bg-red-100 text-red-700 border-none',
		success: 'bg-green-100 text-green-700 border-none',
		warning: 'bg-yellow-100 text-yellow-700 border-none',
		info: 'bg-blue-100 text-blue-700 border-none',
	};

	return (
		<div className='flex justify-center'>
			<div
				className={`flex gap-2 p-4 rounded-4xl max-h-50 my-2 border ${statusStyles[status]}`}
				role='alert' // Accessibility: Alerts should have the `alert` role
				aria-live={status === 'error' || status === 'warning' ? 'assertive' : 'polite'} // Accessibility: Ensure proper announcement priority
				{...props}
			>
				{/* Icon */}
				{/*<div*/}
				{/*	className={`h-6 w-6 flex-shrink-0 ${statusStyles[status]}`}*/}
				{/*	aria-hidden='true' // Accessibility: Icons are decorative and should be ignored by screen readers*/}
				{/*>*/}
				{/*	{status === 'error' && <span className='text-red-400'>⛔️</span>}*/}
				{/*	{status === 'success' && <span className='text-green-500'>✔️</span>}*/}
				{/*	{status === 'warning' && <span className='text-yellow-500'>⚠️</span>}*/}
				{/*	{status === 'info' && <span className='text-blue-500'>ℹ️</span>}*/}
				{/*</div>*/}

				{/* Title */}
				<p className='font-semibold text-gray-800'>{title}</p>
			</div>
		</div>
	);
};
