import { useState, useCallback } from 'react';

export function useDisclosure(defaultState = false) {
	const [isOpen, setIsOpen] = useState(defaultState);

	const onOpen = useCallback(() => setIsOpen(true), []);
	const onClose = useCallback(() => setIsOpen(false), []);
	const onToggle = useCallback(() => setIsOpen((prev) => !prev), []);

	return { isOpen, onOpen, onClose, onToggle };
}
