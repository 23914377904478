import React from 'react';

interface Props {
	children: React.ReactNode;
}

export const InteractionContainer: React.FC<Props> = ({ children }) => {
	return (
		<div className='flex justify-center'>
			<div className='flex flex-col w-[75%] lg:w-[60%]'>{children}</div>
		</div>
	);
};
