import React, { useEffect, useRef } from 'react';
import isEmpty from 'lodash/isEmpty';
import * as am5wc from '@amcharts/amcharts5/wc';
import { WordCloudConfiguration } from '../../model/Visualization.ts';
import { am5 } from '../../../commons/armcharts/AmChartsSetup.ts';
import { useWordCloudData } from '../../hooks/useWordCloudData.ts';
import { VisualizationChartWrapper } from '../VisualizationChartWrapper';
import { VisualizationProps } from '../Visualization/Visualization.tsx';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import am5themes_Responsive from '@amcharts/amcharts5/themes/Responsive';
import { useTheme } from '../../../../theme/components/QuestioryThemeProvider/QuestioryThemeProvider.tsx';

export const WordCloudVisualization: React.FC<VisualizationProps> = ({ visualization, presentationId }) => {
	const conf = visualization.configuration as WordCloudConfiguration;
	const { data, isLoading } = useWordCloudData(presentationId, visualization.id);
	const { fonts } = useTheme();
	const armChartsFontFamily = fonts.armCharts;
	const chartRef = useRef<am5wc.WordCloud | null>(null);

	useEffect(() => {
		const root = am5.Root.new('chartdiv');
		root.setThemes([am5themes_Animated.new(root), am5themes_Responsive.new(root)]);
		if (root && !isEmpty(data)) {
			// root.setThemes([am5themes_Animated.new(root), am5themes_Responsive.new(root)]);
			const series = root.container.children.push(
				am5wc.WordCloud.new(root, {
					height: am5.percent(100),
					minFontSize: am5.percent(20),
					maxFontSize: am5.percent(70),
					autoFit: true,
				})
			);

			series.labels.template.setAll({
				fontFamily: armChartsFontFamily,
				tooltipText: '{category}:[bold]{value}[/]',
				templateField: 'labelSettings',
			});

			const words = data!.map((value) => ({ category: value.category, value: value.value, fill: value.color }));
			series.data.setAll(words);
			series.appear();
			chartRef.current = series;
		}

		return () => {
			root.dispose();
		};
	}, [data, armChartsFontFamily]);

	return (
		<VisualizationChartWrapper title={visualization.title} description={visualization.description} isLoading={isLoading} reference={conf.reference} isNoData={isEmpty(data)} />
	);
};
