import React, { useEffect, useRef } from 'react';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import * as am5wc from '@amcharts/amcharts5/wc';
import { WordCloudConfiguration } from '../../model/Visualization.ts';
import { insertNewlineEveryAmountWords } from '../../../../util/StringUtil.ts';
import { am5 } from '../../../commons/armcharts/AmChartsSetup.ts';
import { VisualizationChartWrapper } from '../VisualizationChartWrapper';
import { VisualizationProps } from '../Visualization/Visualization.tsx';
import am5themes_Responsive from '@amcharts/amcharts5/themes/Responsive';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { useTheme } from '../../../../theme/components/QuestioryThemeProvider/QuestioryThemeProvider.tsx';
import { useSentenceCloudData } from '../../hooks/useSentenceCloudData.ts';

interface WordCloudDataItem {
	category: string;
	weight: number;
	fill: string; // The fill property should be a string
}

export const SentenceCloudVisualization: React.FC<VisualizationProps> = ({ visualization, presentationId }) => {
	const conf = visualization.configuration as WordCloudConfiguration;
	const { data, isLoading } = useSentenceCloudData(presentationId, visualization.id);
	const { colors, fonts } = useTheme();

	const armChartsFontFamily = fonts.armCharts;

	const selectedAnswers = conf.selectedAnswers;
	const sentenceCloudData = data?.map((wordData) => ({
		category: insertNewlineEveryAmountWords(wordData.category, 6),
		weight: wordData.value,
		fill: includes(selectedAnswers, wordData.category) ? colors.success : wordData.color,
	}));
	const cloudData: WordCloudDataItem[] = orderBy(sentenceCloudData, ['weight'], ['desc']).slice(0, 50);

	const chartRef = useRef<am5wc.WordCloud | null>(null);

	useEffect(() => {
		const root = am5.Root.new('chartdiv');

		if (root && !isEmpty(cloudData)) {
			root.setThemes([am5themes_Animated.new(root), am5themes_Responsive.new(root)]);

			const series = root.container.children.push(
				am5wc.WordCloud.new(root, {
					height: am5.percent(90),
					marginTop: 5,
					categoryField: 'category',
					valueField: 'weight',
					minFontSize: 18,
					autoFit: true,
					// maxFontSize: am5.percent(20),
					// dy: -50,
				})
			);

			series.labels.template.setAll({
				tooltipText: '{category}:[bold]{value}[/]',
				templateField: 'labelSettings',
				oversizedBehavior: 'wrap',
			});

			series.data.setAll(cloudData);

			// Configure labels to use the assigned color as the label background
			series.labels.template.setAll({
				fontFamily: armChartsFontFamily,
				fontSize: 18,
				fontWeight: 'bolder',
			});

			series.labels.template.setup = (target) => {
				const dataItem = target.dataItem;
				if (dataItem) {
					const color = dataItem.dataContext as WordCloudDataItem;
					// Create a new background if one doesn't exist, or use the existing one
					let background = target.get('background');
					if (!background) {
						background = target.children.push(
							am5.RoundedRectangle.new(root, {
								fill: am5.color(color.fill),
								fillOpacity: 1,
								cornerRadiusTL: 8,
								cornerRadiusTR: 8,
								cornerRadiusBL: 8,
								cornerRadiusBR: 8,
							})
						);
						target.set('background', background);
					} else {
						// If the background already exists, just update its color
						background.set('fill', am5.color(color.fill));
					}
				}
			};

			series.appear();

			chartRef.current = series;
		}

		return () => {
			root.dispose();
		};
	}, [cloudData, armChartsFontFamily]); // Include getNextColor in the dependencies array

	return (
		<VisualizationChartWrapper
			title={visualization.title}
			description={visualization.description}
			isLoading={isLoading}
			reference={conf.reference}
			referenceColor={colors.success}
			isNoData={isEmpty(cloudData)}
		/>
	);
};
