import React, { createContext, useContext } from 'react';
import { useParams } from 'react-router';
import { useQuestioryTheme } from '../../hooks/useQuestioryTheme.ts';
import { Loading } from '../../../modules/commons/components/Loading';
import { DEFAULT_THEME, PlayerTheme } from '../../model/Theme.ts';

interface Props {
	children?: React.ReactNode;
}

const ThemeContext = createContext<PlayerTheme>(DEFAULT_THEME);

export const QuestioryThemeProvider: React.FC<Props> = ({ children }) => {
	const { presentationId } = useParams();
	const { isPending, data, error } = useQuestioryTheme(presentationId);

	if (isPending) {
		return <Loading />;
	}

	if (error) {
		console.error(`Error fetching theme for presentation ${presentationId}`);
	}

	let customTheme: PlayerTheme = DEFAULT_THEME;

	if (data) {
		customTheme = {
			fonts: {
				heading: data.font,
				body: data.font,
				armCharts: data.font,
				comics: data.font,
			},
			colors: {
				...DEFAULT_THEME.colors,
				background: data.colors.background,
				buttonColor: data.colors.buttonColor,
				buttonTextColor: data.colors.buttonTextColor,
				brand: data.colors.brand,
				textColor: data.colors.textColor,
				inputColor: data.colors.inputColor,
				cardBackgroundColor: data.colors.cardBackgroundColor,
				cardTextColor: data.colors.cardTextColor,
				success: data.colors.success,
				highlight: data.colors.highlight,
				colorScheme: {
					color50: data.colors.colorScheme.color50,
					color100: data.colors.colorScheme.color100,
					color200: data.colors.colorScheme.color200,
					color300: data.colors.colorScheme.color300,
					color400: data.colors.colorScheme.color400,
					color500: data.colors.colorScheme.color500,
					color600: data.colors.colorScheme.color600,
					color700: data.colors.colorScheme.color700,
					color800: data.colors.colorScheme.color800,
					color900: data.colors.colorScheme.color900,
				},
			},
		};
	}

	document.documentElement.style.setProperty('--global-text-color', customTheme.colors.textColor);
	document.documentElement.style.setProperty('--global-bg-color', customTheme.colors.background);
	document.documentElement.style.setProperty('--global-font-family', customTheme.fonts.body);
	return <ThemeContext.Provider value={customTheme}>{children}</ThemeContext.Provider>;
};

export const useTheme = () => {
	return useContext(ThemeContext);
};
