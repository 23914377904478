import React from 'react';
import { SlideT } from '../../model/Presentation.ts';
import { Interaction } from '../../../interaction/components/Interaction';
import { Visualization } from '../../../visualization/components/Visualization';
import { StaticContent } from '../../../staticContent/components/StaticContent';
import { useCurrentPresentation } from '../SliderControlsProvider/SliderControlsProvider.tsx';

interface Props {
	slide: SlideT;
	onInteractionEnd: () => void;
}

export const Element: React.FC<Props> = ({ slide, onInteractionEnd }) => {
	const presentation = useCurrentPresentation();
	return (
		<>
			{slide?.interaction ? (
				<Interaction presentationId={presentation.id} interaction={slide.interaction} isDisabled={presentation.closeParticipations} onInteractionEnd={onInteractionEnd} />
			) : null}
			{slide?.visualization ? <Visualization presentationId={presentation.id} visualization={slide.visualization} /> : null}
			{slide.staticContent ? <StaticContent content={slide.staticContent} /> : null}
		</>
	);
};
