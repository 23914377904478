import React, { useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import * as am5hierarchy from '@amcharts/amcharts5/hierarchy';
import countBy from 'lodash/countBy';
import flatMap from 'lodash/flatMap';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import find from 'lodash/find';
import truncate from 'lodash/truncate';
import { useNodesData } from '../../hooks/useNodesData.ts';
import { insertNewlineEveryAmountWords } from '../../../../util/StringUtil.ts';
import { am5 } from '../../../commons/armcharts/AmChartsSetup.ts';
import am5themes_Responsive from '@amcharts/amcharts5/themes/Responsive';
import { VisualizationChartWrapper } from '../VisualizationChartWrapper';
import { VisualizationProps } from '../Visualization/Visualization.tsx';
import { useTheme } from '../../../../theme/components/QuestioryThemeProvider/QuestioryThemeProvider.tsx';

export const NodesVisualization: React.FC<VisualizationProps> = ({ visualization, presentationId }) => {
	// const conf = visualization.configuration as NodesConfiguration
	const { colors, fonts } = useTheme();
	const { data, isLoading } = useNodesData(presentationId, visualization.id);
	const chartResponsiveFontSizes =
		window.innerWidth < 758
			? {
					minRadius: am5.percent(12),
					maxRadius: am5.percent(10),
				}
			: {
					minRadius: am5.percent(5),
					maxRadius: am5.percent(9),
				};

	const hasData = !isLoading && !isEmpty(data);
	const grouped = groupBy(data, 'key');
	const nodesData = map(grouped, (items, key) => {
		const color: string = find(data, { key })?.color || colors.brand;
		const entries = map(countBy(flatMap(items, 'entries')), (count, entry) => ({
			entry,
			count,
		}));

		const truncateNameLength = 28;
		const newLineEveryAmountWords = 2;
		const name = insertNewlineEveryAmountWords(key, newLineEveryAmountWords);

		return {
			name: name,
			truncatedName: truncate(name, { length: truncateNameLength }),
			nodeSettings: { fill: am5.color(color), stroke: am5.color(color) },
			children: entries.map((entry) => {
				const name = insertNewlineEveryAmountWords(entry.entry, newLineEveryAmountWords);
				return {
					name: name,
					truncatedName: truncate(name, {
						length: truncateNameLength,
					}),
					nodeSettings: {
						fill: am5.color(color),
						stroke: am5.color(color),
					},
					value: entry.count,
				};
			}),
		};
	});

	useEffect(() => {
		const root = am5.Root.new('chartdiv');

		if (root && !isEmpty(nodesData)) {
			root.setThemes([am5themes_Animated.new(root), am5themes_Responsive.new(root)]);

			const chart = root.container.children.push(
				am5.Container.new(root, {
					width: am5.percent(100),
					height: am5.percent(100),
				})
			);

			// Create series
			// https://www.amcharts.com/docs/v5/charts/hierarchy/#Adding
			const series = chart.children.push(
				am5hierarchy.ForceDirected.new(root, {
					height: am5.percent(100),
					valueField: 'value',
					categoryField: 'truncatedName',
					childDataField: 'children',
					minRadius: chartResponsiveFontSizes?.minRadius,
					maxRadius: chartResponsiveFontSizes?.maxRadius,
					singleBranchOnly: false,
					downDepth: 2,
					topDepth: 1,
					initialDepth: 0,
					nodePadding: 10,
					linkWithField: 'linkWith',
					manyBodyStrength: -10,
				})
			);

			series.labels.template.setAll({
				fontFamily: fonts.armCharts,
			});

			series.links.template.set('strength', 0.2);

			series.circles.template.setAll({
				templateField: 'nodeSettings',
			});

			series.outerCircles.template.setAll({
				templateField: 'nodeSettings',
				fillOpacity: 0.7,
				strokeWidth: 1,
				strokeOpacity: 1,
			});

			series.links.template.setAll({
				templateField: 'nodeSettings',
				strokeOpacity: 0,
				strokeWidth: 4,
			});

			series.nodes.template.on('disabled', function (disabled, targetNode) {
				//@ts-expect-error type
				const targetLevel = targetNode?.dataItem?.get('depth');
				if (!disabled) {
					series.nodes.each(function (node) {
						if (
							//@ts-expect-error type
							node.dataItem?.get('depth') === targetLevel &&
							node !== targetNode
						) {
							node.set('disabled', true);
						}
					});
				}
			});

			series.nodes.template.setAll({
				tooltipText: `[fontFamily: ${fonts.armCharts}]{name}[/]`,
			});

			const parentData = [
				{
					children: [...nodesData],
				},
			];
			series.data.setAll(parentData);
			series.set('selectedDataItem', series.dataItems[0]);

			// Make stuff animate on load
			series.appear(1000, 100);
		}
		return () => {
			// Clean up on unmount
			root.dispose();
		};
	}, [nodesData, hasData]);

	return <VisualizationChartWrapper title={visualization.title} description={visualization.description} isLoading={isLoading} isNoData={isEmpty(data)} />;
};
