import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';
import { DrawingInteractionConfigurationT } from '../../model/Interaction.ts';
import { usePreviewPageInfo } from '../../../../pages/PreviewPage/PreviewPage.tsx';
import { saveDrawingEntry } from '../../service/EntryService.ts';
import { InteractionWrapper } from '../InteractionWrapper';
import { Excalidraw, exportToSvg } from '@excalidraw/excalidraw';
import { InteractionContainer } from '../InteractionContainer';
import './excalidraw.css';
import { InteractionProps } from '../Interaction/Interaction.tsx';
import { translate } from '../../../../translate';
import { validateMaxLength, validateMinLength, validateRequired } from '../../validation/InteractionValidation.ts';
import { hasSubmission, recordSubmission } from '../../service/InteractionSubmissionTracker.ts';
import { AlertBox } from '../../../commons/components/AlertBox';
import { Input } from '../../../../components/Input';
import { Textarea } from '../../../../components/Textarea';
import { ImportedDataState } from '@excalidraw/excalidraw/types/data/types';
import { ExcalidrawImperativeAPI } from '@excalidraw/excalidraw/types/types';

interface FormValueType {
	heading: string;
	text: string;
}

export const DrawingInteraction: React.FC<InteractionProps> = ({ presentationId, interaction, isDisabled, onInteractionEnd }) => {
	const config = interaction.configuration as DrawingInteractionConfigurationT;
	const initialData: ImportedDataState = {
		appState: {
			activeTool: {
				type: 'freedraw',
				lastActiveTool: null,
				locked: true,
				customType: null,
			},
			currentItemStrokeWidth: 0.8,
			width: 200,
			height: 200,
			viewModeEnabled: isDisabled,
		},
	};
	const required = true; //interaction.required;
	const alreadySubmitted = hasSubmission(interaction.id);
	const [excalidrawAPI, setExcalidrawAPI] = useState<ExcalidrawImperativeAPI | null>(null);
	const [error, setError] = useState<string | undefined>();
	const {
		register,
		handleSubmit,
		formState: { isSubmitting, errors },
		setFocus,
		reset,
	} = useForm<FormValueType>({
		defaultValues: {
			heading: '',
			text: '',
		},
	});

	const { shouldAutoFocus } = usePreviewPageInfo();
	useEffect(() => {
		if (shouldAutoFocus) {
			setFocus('heading');
		}
	}, [setFocus, shouldAutoFocus]);

	const excalidrawElements = () => {
		if (!excalidrawAPI) {
			return [];
		}
		return excalidrawAPI.getSceneElements();
	};

	const doReset = () => {
		excalidrawAPI?.resetScene();
		reset();
	};

	const onSubmit: SubmitHandler<FormValueType> = async (data) => {
		const elements = excalidrawElements();

		if (isEmpty(elements) && !isEmpty(data.heading) && !alreadySubmitted) {
			setError(translate('Draw something'));
		} else if (!isEmpty(data.heading) && !isEmpty(elements)) {
			const svgExport = await exportToSvg({
				elements,
				appState: {
					...initialData.appState,
					exportWithDarkMode: false,
				},
				files: excalidrawAPI!.getFiles(),
			});
			await saveDrawingEntry(presentationId, interaction.id, {
				text: data.text,
				heading: data.heading,
				drawing: svgExport.outerHTML || '',
			});
			recordSubmission(interaction.id);
			doReset();
			onInteractionEnd();
		} else if (alreadySubmitted || !required) {
			onInteractionEnd();
		}
	};

	const renderInformationInputs = () => {
		return (
			<div className='flex flex-col w-full pt-2 gap-4'>
				{errors.heading && <AlertBox title={errors.heading?.message} />}
				<Input
					placeholder={config.placeholderTitle}
					maxLength={500}
					autoComplete='off'
					disabled={isDisabled}
					{...register('heading', {
						validate: {
							required: (value) => {
								return (required && !alreadySubmitted) || !isEmpty(excalidrawElements()) ? validateRequired(value, translate('Text field is required')) : undefined;
							},
							minLength: (value) => {
								return validateMinLength(value, 3, translate('Field too short'));
							},
							maxLength: (value) => {
								return validateMaxLength(value, 500, translate('Field too long'));
							},
						},
					})}
				/>
				{config.longAnswer ? (
					<>
						<Textarea
							placeholder={config.placeholderDescription}
							rows={5}
							maxLength={2400}
							disabled={isDisabled}
							{...register('text', {
								validate: {
									minLength: (value) => {
										return validateMinLength(value, 3, translate('Field too short'));
									},
									maxLength: (value) => {
										return validateMaxLength(value, 500, translate('Field too long'));
									},
								},
							})}
						/>
						{errors.text && <AlertBox title={errors.text.message} />}
					</>
				) : null}
			</div>
		);
	};

	return (
		<InteractionWrapper interaction={interaction} onSubmit={handleSubmit(onSubmit)} isSubmitDisabled={isSubmitting || isDisabled}>
			<InteractionContainer>{renderInformationInputs()}</InteractionContainer>
			{!isEmpty(error) ? <AlertBox title={error} /> : undefined}
			<div className={`h-[500px] overflow-x-auto my-4 rounded-3xl mx-6 md:mx-0 ${!isEmpty(error) ? 'border-3 border-red-500' : ''}`}>
				<Excalidraw
					excalidrawAPI={(api: ExcalidrawImperativeAPI) => setExcalidrawAPI(api)}
					initialData={{
						...initialData,
					}}
					UIOptions={{
						canvasActions: {
							changeViewBackgroundColor: false,
							export: false,
							loadScene: false,
							saveToActiveFile: false,
							toggleTheme: false,
							saveAsImage: false,
						},
						tools: {
							image: false,
						},
					}}
				/>
			</div>
		</InteractionWrapper>
	);
};
