import React, { useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import { LinkPreview } from '../../../../service/LinkPreviewService.ts';
import { IoMdCloseCircleOutline } from 'react-icons/io';
import { useTheme } from '../../../../../../theme/components/QuestioryThemeProvider/QuestioryThemeProvider.tsx';

interface Props {
	title?: string;
	description?: string;
	imageUrl?: string;
	preview?: LinkPreview;
	hideImage: boolean;
	onRemoveImage: () => void;
}

export const LinkInteractionPreview: React.FC<Props> = ({ title, description, preview, hideImage, onRemoveImage }) => {
	const { colors } = useTheme();
	const actualPreview: LinkPreview | undefined = useMemo(() => {
		if (!preview) return undefined; // Handle cases when linkPreview is undefined or null

		const updatedPreview = { ...preview };

		// Replace title if heading is provided and not empty
		if (!isEmpty(title)) {
			updatedPreview.title = title!;
		}

		// Replace description if text is provided and not empty
		if (!isEmpty(description)) {
			updatedPreview.description = description!;
		}

		return updatedPreview;
	}, [title, description, preview]);

	if (actualPreview) {
		return (
			<div className='max-w-xl flex items-center p-4 border rounded-3xl border-none shadow' style={{ backgroundColor: colors.cardBackgroundColor }}>
				{!hideImage && actualPreview.images?.length > 0 && (
					<div className='relative mr-4'>
						<img src={actualPreview.images[0].url} alt={actualPreview.title} className='rounded-lg object-contain max-w-[200px] max-h-[200px]' />
						<button className='absolute top-0 right-0 p-1 focus:outline-none' aria-label='Remove image' onClick={onRemoveImage}>
							<IoMdCloseCircleOutline size={25} />
						</button>
					</div>
				)}
				<div className='flex flex-col gap-2'>
					<h6 className='my-0 text-xl break-words font-semibold' style={{ color: colors.cardTextColor }}>
						{actualPreview.title}
					</h6>
					<p className='text-lg break-words' style={{ color: colors.cardTextColor }}>
						{actualPreview.description}
					</p>
				</div>
			</div>
		);
	}
};
