import { InteractionProps } from '../../../Interaction/Interaction.tsx';
import { MultipleChoiceConfigurationT } from '../../../../model/Interaction.ts';
import { hasSubmission, recordSubmission } from '../../../../service/InteractionSubmissionTracker.ts';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { InteractionContainer } from '../../../InteractionContainer';
import { InteractionWrapper } from '../../../InteractionWrapper';
import { RadioCard } from '../RadioCard';
import { validateMaxLength, validateRequired } from '../../../../validation/InteractionValidation.ts';
import { QUESTION_INTERACTION_ANSWER_MAX_LENGTH } from '../../../../../../util/Constants.ts';
import { translate } from '../../../../../../translate';
import { MdAdd, MdClose } from 'react-icons/md';
import { CheckIcon } from '../RadioCard/RadioCard.tsx';
import { MultipleChoiceEntryInput, saveMultipleChoiceEntry } from '../../../../service/EntryService.ts';
import { AlertBox } from '../../../../../commons/components/AlertBox';
import { Button } from '../../../../../../components/Button';
import { Input } from '../../../../../../components/Input';
import { useTheme } from '../../../../../../theme/components/QuestioryThemeProvider/QuestioryThemeProvider.tsx';

interface FormValueType {
	singleEntry: string;
	singleOtherEntry: string;
}

export function SingleResponse({ presentationId, interaction, isDisabled, onInteractionEnd }: InteractionProps) {
	const config = interaction.configuration as MultipleChoiceConfigurationT;
	const { colors } = useTheme();
	const required = true; //interaction.required;
	const [otherEntryEnabled, setOtherEntryEnabled] = useState(false);
	const alreadySubmitted = hasSubmission(interaction.id);
	const {
		handleSubmit,
		register,
		formState: { isSubmitting, errors },
		watch,
		reset,
		setValue,
		setError,
	} = useForm<FormValueType>({
		defaultValues: {
			singleEntry: '',
			singleOtherEntry: '',
		},
	});

	const watchSingleOtherEntry = watch(`singleOtherEntry`);

	const handleShowOther = (show: boolean) => async () => {
		reset();
		setOtherEntryEnabled(show);
	};

	const onSubmit = async (data: FormValueType) => {
		if (data.singleEntry.length === 0 && data.singleOtherEntry.length === 0) {
			setError('singleEntry', { type: 'required' });
		}

		let entry: MultipleChoiceEntryInput | undefined = undefined;
		if (data.singleEntry.length > 0) {
			entry = {
				value: data.singleEntry,
				isOther: false,
			};
		} else if (data.singleOtherEntry.length > 0) {
			entry = {
				value: data.singleOtherEntry,
				isOther: true,
			};
		}

		if (entry) {
			await saveMultipleChoiceEntry(presentationId, interaction.id, [entry]);
			recordSubmission(interaction.id);
			reset();
			onInteractionEnd();
		} else if (alreadySubmitted || !required) {
			onInteractionEnd();
		}
	};

	return (
		<InteractionWrapper interaction={interaction} onSubmit={handleSubmit(onSubmit)} isSubmitDisabled={isSubmitting || isDisabled}>
			<InteractionContainer>
				{errors.singleEntry ? <AlertBox title={'Pick an option'} /> : null}
				<div className='flex flex-col gap-4'>
					{config.options.map((option, index) => (
						<RadioCard
							key={option + index}
							option={option}
							disabled={isDisabled}
							currentOption={watch('singleEntry')}
							{...register('singleEntry', {
								onChange: () => {
									setValue('singleOtherEntry', '');
								},
								validate: {
									required: (value) => {
										const multipleOtherEntry = watch('singleOtherEntry');
										if (multipleOtherEntry.length > 0) {
											return true;
										}
										return required && !alreadySubmitted ? validateRequired(value, 'Select an option') : undefined;
									},
								},
							})}
						/>
					))}

					{otherEntryEnabled ? (
						<div
							id='other-entry'
							className='w-full flex justify-between gap-1 px-2 pt-2 pb-1 rounded-full shadow-sm'
							style={{
								backgroundColor: colors.cardBackgroundColor,
								color: colors.cardTextColor,
							}}
						>
							<div className='flex flex-col w-[90%] mb-1 relative'>
								<Input
									maxLength={QUESTION_INTERACTION_ANSWER_MAX_LENGTH}
									autoComplete='off'
									className='pe-12'
									{...register('singleOtherEntry', {
										onChange: () => setValue('singleEntry', ''),
										validate: {
											maxLength: (value) => validateMaxLength(value, QUESTION_INTERACTION_ANSWER_MAX_LENGTH, translate('Field too long')),
										},
									})}
								/>
								<Button
									className='absolute right-2 top-1/2 -translate-y-1/2 p-2 rounded-full bg-transparent!'
									rightIcon={<MdClose className='w-5 h-5' style={{ color: colors.brand }} />}
									onClick={handleShowOther(false)}
									aria-label='Delete'
								/>
							</div>

							<div className='mr-[0.50rem] flex items-center'>
								{watchSingleOtherEntry?.length > 0 ? (
									<div className='w-8 h-8 flex items-center justify-center rounded-full' style={{ backgroundColor: colors.buttonColor }}>
										<CheckIcon />
									</div>
								) : (
									<div
										className='w-8 h-8 flex items-center justify-center rounded-full border-2'
										style={{
											backgroundColor: colors.cardBackgroundColor,
											borderColor: colors.brand,
										}}
									/>
								)}
							</div>
						</div>
					) : null}

					{config.allowOthers && !otherEntryEnabled && (
						<div className='flex justify-end'>
							<Button leftIcon={<MdAdd />} onClick={handleShowOther(true)} disabled={isDisabled}>
								Add other
							</Button>
						</div>
					)}
				</div>
			</InteractionContainer>
		</InteractionWrapper>
	);
}
