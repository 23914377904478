import { CSSProperties, forwardRef, TextareaHTMLAttributes } from 'react';
import classes from './Textarea.module.css';
import { useTheme } from '../../theme/components/QuestioryThemeProvider/QuestioryThemeProvider.tsx';

interface Props extends TextareaHTMLAttributes<HTMLTextAreaElement> {}

const Textarea = forwardRef<HTMLTextAreaElement, Props>(({ className, ...props }, ref) => {
	const { colors } = useTheme();

	return (
		<textarea
			ref={ref}
			style={
				{
					'--placeholder-color': colors.placeholderColor,
					'--background-color': colors.inputColor,
					'--text-color': colors.textColor,
					'--border-color': colors.brand,
				} as CSSProperties
			}
			className={`
          rounded-3xl
          p-3
					w-full
          shadow-md
          outline-none
          focus-visible:shadow-none
          transition-shadow duration-200
          ${classes.textarea}
          ${className}
          disabled:opacity-50 disabled:cursor-not-allowed
        `}
			{...props}
		/>
	);
});

Textarea.displayName = 'Textarea';

export { Textarea };
