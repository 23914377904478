import React from 'react';
import { useLocation, useParams } from 'react-router';
import isEmpty from 'lodash/isEmpty';
import { Element } from '../../modules/presentation/components/Element';
import { Loading } from '../../modules/commons/components/Loading';
import { useSlide } from '../../modules/presentation/hooks/useSlide.ts';
import { hexToRGBA } from '../../util/ColorUtils.ts';
import { AlertBox } from '../../modules/commons/components/AlertBox';
import { SliderControlsProvider } from '../../modules/presentation/components/SliderControlsProvider';
import { useTheme } from '../../theme/components/QuestioryThemeProvider/QuestioryThemeProvider.tsx';

export function usePreviewPageInfo() {
	const location = useLocation();
	const isPreviewPage = location.pathname.includes('/preview');
	const shouldAutoFocus = false; //!isPreviewPage;
	return {
		isPreviewPage,
		shouldAutoFocus,
	};
}

export const PreviewPage: React.FC = () => {
	const { presentationId, currentSlide } = useParams();
	const { colors } = useTheme();
	const { data: actualSlide, isPending, isError } = useSlide(presentationId!, currentSlide!);

	const handleOnInteractionEnd = () => {
		// Interaction end handler logic
	};

	if (isPending) {
		return <Loading />;
	}

	if (isError) {
		return <AlertBox title={`Error fetching Slide: ${currentSlide}`} />;
	}

	if (isEmpty(actualSlide)) {
		return <AlertBox title='Slide not found' />;
	}

	const bgImage = !isEmpty(actualSlide.imageBackgroundUrl)
		? `linear-gradient(${hexToRGBA(colors.background, 0.5)}, ${hexToRGBA(colors.background, 0.5)}), url(${actualSlide.imageBackgroundUrl})`
		: undefined;

	return (
		<div id='preview-wrapper' className='h-svh bg-no-repeat bg-cover bg-center' style={{ backgroundImage: bgImage }}>
			<SliderControlsProvider
				currentSlideId={actualSlide.id}
				presentation={{
					id: presentationId!,
					description: '',
					slides: [actualSlide],
					hideControls: true,
					hideProgressBar: true,
					hideQuestioryBrand: false,
					closeParticipations: false,
				}}
			>
				<Element slide={actualSlide} onInteractionEnd={handleOnInteractionEnd} />
			</SliderControlsProvider>
		</div>
	);
};
