export const translations = {
	en: {
		translation: {
			Submit: undefined,
			'It seems there are no answers to this question yet.': undefined,
			'Add a title to your drawing': undefined,
			// 'Add a description': undefined,
			'Select image': undefined,
			'Enter a link': undefined,
			'Field too short': 'is the minimum limit per response',
			'Field too long': 'is the maximum limit per response',
			'Please complete at least one answer before submitting': undefined,
			'Please share an answer before submitting': undefined,
			'Draw something': 'Draw something before submitting',
			'Please enter a valid image url': undefined,
			'Number field is required': undefined,
			'Text field is required': undefined,
			'Please select an icon before submitting': undefined,
			Prev: undefined,
			Next: undefined,
			Other: undefined,
			Submitted: undefined,
			Skip: undefined,
		},
	},
	es: {
		translation: {
			Submit: 'Enviar',
			'It seems there are no answers to this question yet.': 'Parece que no hay respuestas a esta pregunta todavía.',
			'Add a title to your drawing': 'Añade título a tu dibujo',
			// 'Add a description': 'Añade una descripción',
			'Select image': 'Selecciona una imágen',
			'Enter a link': 'Ingresa un link',
			'Field too short': 'es el límite mínimo por respuesta',
			'Field too long': 'es el límite máximo por respuesta',
			'Please complete at least one answer before submitting': 'Por favor completa al menos una respuesta antes de enviar',
			'Please share an answer before submitting': 'Por favor completa una respuesta antes de enviar',
			'Draw something': 'Haz un dibujo antes de enviar',
			'Please enter a valid image url': 'Ingresa una url válida',
			'Number field is required': 'El campo de número es requerido',
			'Text field is required': 'El campo de texto es requerido',
			'Please select an icon before submitting': 'Selecciona un ícono antes de enviar',
			Prev: 'Anterior',
			Next: 'Siguiente',
			Other: 'Otra',
			Submitted: 'Guardado',
			Skip: 'Omitir',
		},
	},
} as const;
