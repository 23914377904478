import { ContentConfigurationCallToActionT, StaticContentT } from '../../model/StaticContent.ts';
import { Title } from '../../../commons/components/Title';
import { HiOutlineExternalLink } from 'react-icons/hi';
import { MdChevronRight } from 'react-icons/md';
import { useTheme } from '../../../../theme/components/QuestioryThemeProvider/QuestioryThemeProvider.tsx';

interface Props {
	content: StaticContentT;
}

export function ContentCallToAction({ content }: Props) {
	const conf = content.configuration as ContentConfigurationCallToActionT;
	const { colors } = useTheme();
	return (
		<div id='statement-wrapper' className='m-auto w-[90%] xl:w-[80%] p-4'>
			<Title title={content.title} description={content.description} />
			<div className='flex justify-center h-full mb-6'>
				<div className='flex flex-col w-[75%] lg:w-[60%] gap-4'>
					{conf.items.map((item, index) => (
						<a key={item.label + index} href={item.reference} target={conf.openInNewTab ? '_blank' : '_self'} rel={conf.openInNewTab ? 'noopener noreferrer' : ''}>
							<div className='w-full rounded-full shadow-md py-4 px-8 flex justify-between' style={{ backgroundColor: colors.buttonColor }}>
								<p className='text-lg md:text-xl lg:text-2xl font-bold uppercase break-words' style={{ color: colors.buttonTextColor }}>
									{item.label}
								</p>
								{conf.openInNewTab ? (
									<HiOutlineExternalLink className='w-7 h-7' style={{ color: colors.buttonTextColor }} />
								) : (
									<MdChevronRight className='w-7 h-7' style={{ color: colors.buttonTextColor }} />
								)}
							</div>
						</a>
					))}
				</div>
			</div>
		</div>
	);
}
