import { MultipleChoiceConfigurationT } from '../../../../model/Interaction.ts';
import { hasSubmission, recordSubmission } from '../../../../service/InteractionSubmissionTracker.ts';
import { InteractionProps } from '../../../Interaction/Interaction.tsx';
import { useFieldArray, useForm } from 'react-hook-form';
import { InteractionWrapper } from '../../../InteractionWrapper';
import { InteractionContainer } from '../../../InteractionContainer';
import { CheckboxCard } from '../CheckboxCard';
import { validateMaxLength, validateRequired } from '../../../../validation/InteractionValidation.ts';
import { QUESTION_INTERACTION_ANSWER_MAX_LENGTH } from '../../../../../../util/Constants.ts';
import { translate } from '../../../../../../translate';
import { MdAdd, MdClose } from 'react-icons/md';
import isEmpty from 'lodash/isEmpty';
import { MultipleChoiceEntryInput, saveMultipleChoiceEntry } from '../../../../service/EntryService.ts';
import { AlertBox } from '../../../../../commons/components/AlertBox';
import { Button } from '../../../../../../components/Button';
import { Input } from '../../../../../../components/Input';
import { CheckIcon } from '../RadioCard/RadioCard.tsx';
import { useTheme } from '../../../../../../theme/components/QuestioryThemeProvider/QuestioryThemeProvider.tsx';

interface FormValueType {
	multipleEntry: string[];
	multipleOtherEntry: string[];
}

export function MultipleResponse({ presentationId, interaction, isDisabled, onInteractionEnd }: InteractionProps) {
	const config = interaction.configuration as MultipleChoiceConfigurationT;
	const { colors } = useTheme();
	const required = true; //interaction.required;
	const alreadySubmitted = hasSubmission(interaction.id);

	const {
		handleSubmit,
		register,
		formState: { isSubmitting, errors },
		watch,
		control,
		reset,
		setValue,
	} = useForm<FormValueType>({
		defaultValues: {
			multipleEntry: [],
			multipleOtherEntry: [],
		},
	});

	const { fields, append, remove } = useFieldArray({
		//@ts-expect-error: Ignore
		name: 'multipleOtherEntry',
		control,
		rules: {
			required: false,
			maxLength: config.maxOthers,
		},
	});

	const multipleOtherEntry = watch('multipleOtherEntry');
	const calculateMaxAnswer = () => {
		if (config.allowOthers) {
			return config.multipleAnswer ? config.maxOthers : 1;
		} else {
			return 0;
		}
	};

	const canAdd = fields.length < calculateMaxAnswer();
	const handleAdd = () => {
		if (canAdd) {
			append('', { shouldFocus: true });
		}
	};

	const handleRemove = (index: number) => () => {
		remove(index);
	};

	const onSubmit = async (data: FormValueType) => {
		const entries: string[] = data.multipleEntry;
		const otherEntries: string[] = data.multipleOtherEntry.filter((s) => s);
		if (!isEmpty(entries) || !isEmpty(otherEntries)) {
			const newEntries: MultipleChoiceEntryInput[] = entries.map((value) => ({ value, isOther: false }));
			const newOtherEntries: MultipleChoiceEntryInput[] = otherEntries.map((value) => ({ value, isOther: true }));
			const data = [...newEntries, ...newOtherEntries];
			await saveMultipleChoiceEntry(presentationId, interaction.id, data);
			recordSubmission(interaction.id);
			reset();
			setValue('multipleEntry', []);
			setValue('multipleOtherEntry', []);
			onInteractionEnd();
		} else if (alreadySubmitted || !required) {
			onInteractionEnd();
		}
	};

	return (
		<InteractionWrapper interaction={interaction} onSubmit={handleSubmit(onSubmit)} isSubmitDisabled={isSubmitting || isDisabled}>
			<InteractionContainer>
				{errors.multipleEntry ? <AlertBox title={'Pick an option'} /> : null}
				<div className='flex flex-col gap-4'>
					{config.options.map((option, index) => (
						<CheckboxCard
							key={index}
							option={option}
							disabled={isDisabled}
							{...register('multipleEntry', {
								validate: {
									required: (value) => {
										const multipleOtherEntry = watch('multipleOtherEntry').filter((s) => s && s.length > 0);
										if (multipleOtherEntry.length > 0) {
											return true;
										}
										return required && !alreadySubmitted ? validateRequired(value, 'Select an option') : undefined;
									},
								},
							})}
						/>
					))}
					{multipleOtherEntry.length > 0 ? (
						<>
							{fields.map((field, index) => (
								<div
									id={field.id}
									key={field.id}
									className='w-full flex justify-between gap-1 px-2 pt-2 pb-1 border-none rounded-full shadow-sm'
									style={{
										backgroundColor: colors.cardBackgroundColor,
										color: colors.cardTextColor,
									}}
								>
									<div className='flex flex-col w-[90%] mb-1 relative'>
										<Input
											id={field.id}
											maxLength={QUESTION_INTERACTION_ANSWER_MAX_LENGTH}
											autoComplete='off'
											className='pe-11'
											{...register(`multipleOtherEntry.${index}`, {
												validate: {
													maxLength: (value) => validateMaxLength(value, QUESTION_INTERACTION_ANSWER_MAX_LENGTH, translate('Field too long')),
												},
											})}
										/>
										<Button
											className='absolute right-2 top-1/2 -translate-y-1/2 	rounded-full bg-transparent!'
											rightIcon={<MdClose className='w-5 h-5' style={{ color: colors.brand }} />}
											onClick={handleRemove(index)}
											aria-label='Delete'
										/>
									</div>
									<div className='mr-[0.70rem] flex items-center'>
										<div
											className={`w-7 h-7 flex items-center justify-center border-2 rounded-md transition-all `}
											style={{
												borderColor: colors.brand,
												backgroundColor: watch(`multipleOtherEntry.${index}`)?.length > 0 ? colors.buttonColor : 'transparent',
												boxShadow: watch(`multipleOtherEntry.${index}`)?.length > 0 ? 'none' : 'inherit',
											}}
										>
											{watch(`multipleOtherEntry.${index}`)?.length > 0 && <CheckIcon />}
										</div>
									</div>
								</div>
							))}
						</>
					) : null}
					{config.allowOthers && canAdd && (
						<div className='flex justify-end'>
							<Button leftIcon={<MdAdd />} onClick={handleAdd} disabled={isDisabled}>
								Add other
							</Button>
						</div>
					)}
				</div>
			</InteractionContainer>
		</InteractionWrapper>
	);
}
