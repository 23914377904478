import { PlayerTheme } from '../model/Theme.ts';
import ApiClient from '../../apiClient';

export interface ApiTheme {
	font: string;
	colors: PlayerTheme['colors'];
}

export const getThemeByPresentationId = async (presentationId: string): Promise<ApiTheme> => {
	return (await ApiClient.getInstance().get(`/client-app/presentations/${presentationId}/theme`)).data;
};
