import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { useComicData } from '../../hooks/useComicData.ts';
import { Loading } from '../../../commons/components/Loading';
import { VisualizationWrapper } from '../VisualizationWrapper';
import { VisualizationProps } from '../Visualization/Visualization.tsx';
import { useTheme } from '../../../../theme/components/QuestioryThemeProvider/QuestioryThemeProvider.tsx';

const textBoxClasses = 'relative bg-white rotate-[1deg] mb-2 ml-1 p-4';

export const ComicVisualization: React.FC<VisualizationProps> = ({ presentationId, visualization }) => {
	// const conf = visualization.configuration as DrawingCardsConfiguration;
	const { data, isLoading } = useComicData(presentationId, visualization.id);
	const { fonts } = useTheme();
	const comicsFontFamily = fonts.comics;

	if (isLoading) {
		return <Loading />;
	}

	// Function to dynamically set the border style
	const getDynamicBorderStyle = (index: number) => ({
		borderTop: index % 2 === 0 ? '2px black solid' : 'none',
		borderRight: '2px black solid',
		borderBottom: index % 2 !== 0 ? '2px black solid' : 'none',
		borderLeft: 'none',
	});

	const gridClasses = 'grid gap-4 sm:gap-6 md:gap-8 lg:gap-8 ' + 'grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 ' + 'max-h-[70%] overflow-y-auto p-2 mx-16 mb-8';

	return (
		<VisualizationWrapper visualization={visualization} isNoData={!isLoading && isEmpty(data?.entries)}>
			<div className={gridClasses}>
				{data?.entries?.map((entry, idx) => (
					<div key={entry.heading} className={`relative flex ${idx % 2 === 0 ? 'flex-col-reverse' : 'flex-col'} bg-white border-2 border-black p-2`}>
						<div className='absolute top-[-2px] right-[-2px] bottom-[-2px] left-[-2px] z-10 border-2 border-black rotate-[-1deg]'></div>
						<div className={textBoxClasses} style={getDynamicBorderStyle(idx)}>
							<div className='flex flex-col'>
								<p className='break-words my-2 text-lg text-black!' style={{ fontFamily: comicsFontFamily }}>
									{entry.heading}
								</p>
								<p className='text-sm text-black' style={{ fontFamily: comicsFontFamily }}>
									{entry.text}
								</p>
							</div>
						</div>
						<div className='flex-1 self-center'>
							<img src={`data:image/svg+xml;base64,${btoa(decodeURIComponent(encodeURIComponent(entry.drawing)))}`} alt='SVG Content' className='w-full h-full' />
						</div>
					</div>
				)) ?? null}
			</div>
		</VisualizationWrapper>
	);
};
