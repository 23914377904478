import React, { CSSProperties, forwardRef } from 'react';
import classes from './RadioCard.module.css';
import { useTheme } from '../../../../../../theme/components/QuestioryThemeProvider/QuestioryThemeProvider.tsx';

type RadioProps = {
	option: string;
	currentOption: string;
} & React.InputHTMLAttributes<HTMLInputElement>; // Extend standard input attributes

export const RadioCard = forwardRef<HTMLInputElement, RadioProps>(({ option, currentOption, ...props }, ref) => {
	const { colors } = useTheme();
	return (
		<label
			className={`flex w-full cursor-pointer gap-2 p-3 items-center text-center rounded-full border-2 border-transparent shadow-md transition-all ${classes.radioCard} ${props.disabled ? classes.disabled : ''}`}
			style={
				{
					color: colors.cardTextColor,
					borderColor: 'transparent',
					'--background-color': colors.cardBackgroundColor,
					'--hover-background-color': colors.highlight,
				} as CSSProperties
			}
		>
			<div className='flex-1'>
				<p className='font-light break-words text-lg md:text-xl lg:text-2xl'>{option}</p>
			</div>
			<input type='radio' {...props} ref={ref} value={option} hidden />
			<div className='flex items-center'>
				{option === currentOption ? (
					<div className='w-8 h-8 flex items-center justify-center rounded-full' style={{ backgroundColor: colors.buttonColor }}>
						<CheckIcon />
					</div>
				) : (
					<div
						className='w-8 h-8 flex items-center justify-center rounded-full border-2'
						style={{
							backgroundColor: colors.cardBackgroundColor,
							borderColor: colors.brand,
						}}
					/>
				)}
			</div>
		</label>
	);
});

export function CheckIcon() {
	return (
		<svg className='w-4 h-4 text-white' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 10' fill='currentColor'>
			<polyline fill='none' strokeWidth='2px' stroke='currentColor' strokeDasharray='16px' points='1.5 6 4.5 9 10.5 1' />
		</svg>
	);
}

RadioCard.displayName = 'RadioCardInput'; // It's good practice to set a displayName when using forwardRef.
