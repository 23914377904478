import React, { useState } from 'react';
import { InteractionContainer } from '../InteractionContainer';
import isEmpty from 'lodash/isEmpty';
import { SubmitHandler, useForm } from 'react-hook-form';
import { InteractionWrapper } from '../InteractionWrapper';
import { LinkEntry, saveLinkEntry } from '../../service/EntryService.ts';
import { usePreviewPageInfo } from '../../../../pages/PreviewPage/PreviewPage.tsx';
import { LinkInteractionPreview } from './_/LinkInteractionPreview';
import { isValidUrl, toValidUrl } from '../../../commons/util/UrlValidationUtils.ts';
import { useLinkPreview } from '../../hooks/useLinkPreview.ts';
import { Loading } from '../../../commons/components/Loading';
import { MdLink } from 'react-icons/md';
import { InteractionProps } from '../Interaction/Interaction.tsx';
import { translate } from '../../../../translate';
import { validateMaxLength, validateMinLength, validateRequired } from '../../validation/InteractionValidation.ts';
import { hasSubmission, recordSubmission } from '../../service/InteractionSubmissionTracker.ts';
import { useDebounce } from '../../../../hooks/useDebounce.ts';
import { LinkInteractionConfigurationT } from '../../model/Interaction.ts';
import { AlertBox } from '../../../commons/components/AlertBox';
import { Input } from '../../../../components/Input';
import { Textarea } from '../../../../components/Textarea';

export const LinkInteraction: React.FC<InteractionProps> = ({ presentationId, interaction, isDisabled, onInteractionEnd }) => {
	const config = interaction.configuration as LinkInteractionConfigurationT;
	const required = true; //interaction.required;
	const alreadySubmitted = hasSubmission(interaction.id);
	const [customTitle, setCustomTitle] = useState('');
	const [customDescription, setCustomDescription] = useState('');
	const [hideImage, setHideImage] = useState(false);

	const {
		register,
		handleSubmit,
		watch,
		setValue,
		getValues,
		formState: { isSubmitting, errors },
		reset,
	} = useForm<LinkEntry>({
		defaultValues: {
			heading: '',
			text: '',
			link: '',
			imageUrl: '',
		},
	});
	const { shouldAutoFocus } = usePreviewPageInfo();

	const link = watch('link');
	const debounceUrl = useDebounce(link);
	const { isFetching, data: linkPreview } = useLinkPreview(isValidUrl(debounceUrl) ? debounceUrl : '');

	if (linkPreview && !hideImage && !isEmpty(linkPreview.images)) {
		setValue('imageUrl', linkPreview.images[0].url);
	}

	if (linkPreview && isEmpty(customTitle)) {
		setValue('heading', linkPreview.title);
	}
	if (linkPreview && isEmpty(customDescription)) {
		setValue('text', linkPreview.description);
	}

	const onSubmit: SubmitHandler<LinkEntry> = async (data) => {
		if (!isEmpty(data.heading) && !isEmpty(data.link)) {
			await saveLinkEntry(presentationId, interaction.id, { ...data, link: toValidUrl(data.link) });
			reset();
			setCustomTitle('');
			setCustomDescription('');
			recordSubmission(interaction.id);
			setHideImage(false);
			onInteractionEnd();
		} else if (alreadySubmitted || !required) {
			onInteractionEnd();
		}
	};

	const onRemoveImage = () => {
		setHideImage(true);
		setValue('imageUrl', '');
	};

	const values = getValues();
	const hasData = !isEmpty(values.heading) || !isEmpty(values.text) || !isEmpty(values.link);
	return (
		<InteractionWrapper interaction={interaction} onSubmit={handleSubmit(onSubmit)} isSubmitDisabled={isSubmitting || isDisabled}>
			<InteractionContainer>
				<div className='flex flex-col items-center gap-4'>
					{errors.link?.type === 'required' && <AlertBox title={errors.link.message} />}
					<div className='relative w-full my-2'>
						<div className='flex z-2 absolute left-3 top-2 text-gray-500'>
							<MdLink size={32} />
						</div>
						<Input
							autoFocus={shouldAutoFocus}
							autoComplete='off'
							className='ps-12'
							disabled={isDisabled}
							placeholder={config.placeholder}
							{...register('link', {
								validate: {
									required: (value) => {
										return (required && !alreadySubmitted) || hasData ? validateRequired(value, translate('Enter a link')) : undefined;
									},
									validUrl: (v) => (!isValidUrl(v) ? 'invalid url' : undefined),
								},
							})}
						/>
					</div>

					{errors.link?.type === 'pattern' && <AlertBox title='Provide a URL link' />}

					{linkPreview && (
						<>
							{errors.heading && <AlertBox title={errors.heading.message} />}
							<Input
								maxLength={500}
								autoComplete='off'
								className={isEmpty(linkPreview) ? 'hidden' : 'block'}
								{...register('heading', {
									validate: {
										required: (value) => {
											return (required && !alreadySubmitted) || hasData ? validateRequired(value, translate('Text field is required')) : undefined;
										},
										minLength: (value) => validateMinLength(value, 3, translate('Field too short')),
										maxLength: (value) => validateMaxLength(value, 500, translate('Field too long')),
									},
									onChange: (e) => setCustomTitle(e.target.value),
								})}
							/>
						</>
					)}

					<>
						{errors.text && <AlertBox title={errors.text.message} />}
						<Textarea
							rows={5}
							autoComplete='off'
							maxLength={2400}
							className={isEmpty(linkPreview) ? 'hidden' : 'block'}
							{...register('text', {
								validate: {
									maxLength: (value) => validateMaxLength(value, 500, translate('Field too long')),
								},
								onChange: (e) => setCustomDescription(e.target.value),
							})}
						/>
					</>

					<input hidden {...register('imageUrl')} />

					<div className='w-4/5 flex justify-center'>
						{isFetching ? (
							<Loading />
						) : (
							<LinkInteractionPreview title={customTitle} description={customDescription} preview={linkPreview} hideImage={hideImage} onRemoveImage={onRemoveImage} />
						)}
					</div>
				</div>
			</InteractionContainer>
		</InteractionWrapper>
	);
};
