import React, { useEffect, useRef, useState } from 'react';
import { CategorisedInteractionConfigurationT } from '../../model/Interaction.ts';
import { useForm } from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';
import { CategorisedEntry, saveCategorisedEntry } from '../../service/EntryService.ts';
import { InteractionWrapper } from '../InteractionWrapper';
import { CategorisedCategory } from './_/CategorisedCategory';
import { InteractionProps } from '../Interaction/Interaction.tsx';
import { hasSubmission, recordSubmission } from '../../service/InteractionSubmissionTracker.ts';
import { MdOutlineArrowBackIos, MdOutlineArrowForwardIos } from 'react-icons/md';
import { translate } from '../../../../translate';
import { AlertBox } from '../../../commons/components/AlertBox';
import { IconButton } from '../../../../components/IconButton';
import classes from './CategorisedInteraction.module.css';
import { useTheme } from '../../../../theme/components/QuestioryThemeProvider/QuestioryThemeProvider.tsx';

type Categories = {
	value: string;
};

export interface Entry {
	entry: Record<string, Categories[]>;
}

export const CategorisedInteraction: React.FC<InteractionProps> = ({ presentationId, interaction, isDisabled, onInteractionEnd }) => {
	const config = interaction.configuration as CategorisedInteractionConfigurationT;
	const { colors } = useTheme();
	const required = true; //interaction.required;
	const [error, setError] = useState<string | undefined>();
	const alreadySubmitted = hasSubmission(interaction.id);
	const defaultValues = config.categories.reduce(
		(acc, value) => {
			acc[value.id] = [];
			return acc;
		},
		{} as Record<string, Categories[]>
	);
	const {
		register,
		handleSubmit,
		control,
		formState: { isSubmitting },
		reset,
	} = useForm<Entry>({
		defaultValues: {
			entry: defaultValues,
		},
		shouldFocusError: false,
	});

	const onSubmit = async (data: Entry) => {
		const actualEntries: CategorisedEntry[] = Object.entries(data.entry)
			//Filter all empty values
			.filter(([key, entry]) => {
				// Filter all empty categories
				const categoriesFilter = entry.filter((cat) => !isEmpty(cat.value));
				return key.trim() !== '' && !isEmpty(categoriesFilter);
			})
			.map(([key, entry]) => {
				const parsedKey = parseInt(key, 10);
				const foundStatement = config.categories.find((cat) => cat.id === parsedKey);
				if (!foundStatement) {
					return {
						key: 'undefined',
						entries: [],
					};
				}
				const values = entry.filter((e) => !isEmpty(e.value.trim())).map((e) => e.value);
				return {
					key: foundStatement.category, // Forcing uncheck given that we will always find a statement
					entries: values,
				};
			})
			// However in the strange case that a statement was not found, we filter it
			.filter((value) => !isEmpty(value.key) && !isEmpty(value.entries));
		if (isEmpty(actualEntries) && required && !alreadySubmitted) {
			setError(translate('Please complete at least one answer before submitting'));
		} else if (!isEmpty(actualEntries)) {
			await saveCategorisedEntry(presentationId, interaction.id, actualEntries);
			recordSubmission(interaction.id);
			reset();
			onInteractionEnd();
		} else if (alreadySubmitted || !required) {
			onInteractionEnd();
		}
	};

	const gridRef = useRef<HTMLDivElement>(null);

	const scroll = (direction: 'left' | 'right') => {
		if (gridRef.current) {
			const { scrollLeft, clientWidth } = gridRef.current;
			const scrollTo = direction === 'left' ? scrollLeft - clientWidth : scrollLeft + clientWidth;
			gridRef.current.scrollTo({ left: scrollTo, behavior: 'smooth' });
		}
	};

	useEffect(() => {
		if (gridRef.current) {
			gridRef.current.scrollTo({ left: 0, behavior: 'instant' });
		}
	}, []);

	let navigationDisplayArrow = 'hidden';
	if (config.categories.length === 2) {
		navigationDisplayArrow = 'inline-flex md:hidden';
	} else if (config.categories.length === 3) {
		navigationDisplayArrow = 'inline-flex lg:hidden';
	} else if (config.categories.length >= 4) {
		navigationDisplayArrow = 'inline-flex';
	}

	const justifyClass = config.categories.length <= 2 ? 'md:justify-center' : 'md:justify-start';
	const lgJustifyClass = config.categories.length <= 3 ? 'lg:justify-center' : 'lg:justify-start';

	return (
		<InteractionWrapper interaction={interaction} onSubmit={handleSubmit(onSubmit)} isSubmitDisabled={isSubmitting || isDisabled}>
			{!isEmpty(error) ? <AlertBox title={error} /> : undefined}
			<div
				className='flex items-center mx-4 px-4 rounded-2xl'
				style={{
					backgroundColor: '#ffffff7d', // Matches the semi-transparent white background
				}}
			>
				<IconButton variant='inverted' aria-label='Scroll left' icon={<MdOutlineArrowBackIos />} onClick={() => scroll('left')} className={`${navigationDisplayArrow}`} />
				<div
					id='grid'
					ref={gridRef}
					className={`grid auto-cols-[100%] md:auto-cols-[48%] lg:auto-cols-[32%] gap-2 w-[100%] my-8 mx-4 ${classes.categorisedGrid} ${justifyClass} ${lgJustifyClass}`}
				>
					{config.categories.map((category) => (
						<div id='grid-item' key={category.id} className='flex flex-col snap-start p-4 m-2 rounded-2xl shadow-md' style={{ backgroundColor: colors.cardBackgroundColor }}>
							<div className='flex items-center'>
								<div className='w-3 h-3 rounded-full mr-2' style={{ backgroundColor: category.color }} />
								<h2 className='text-md font-semibold break-words'>{category.category}</h2>
							</div>
							<CategorisedCategory control={control} register={register} interaction={interaction} disabled={isDisabled} categoryEntry={category} />
						</div>
					))}
				</div>
				<IconButton
					variant='inverted'
					aria-label='Scroll right'
					icon={<MdOutlineArrowForwardIos />}
					onClick={() => scroll('right')}
					className={`bg-transparent ${navigationDisplayArrow}`}
				/>
			</div>
		</InteractionWrapper>
	);
};
