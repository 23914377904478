import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';
import { ValuationConfigurationStatementItem, ValuationInteractionConfigurationT } from '../../model/Interaction.ts';
import { saveValuationEntry, ValuationEntry } from '../../service/EntryService.ts';
import { InteractionWrapper } from '../InteractionWrapper';
import { InteractionProps } from '../Interaction/Interaction.tsx';
import { hasSubmission, recordSubmission } from '../../service/InteractionSubmissionTracker.ts';
import { translate } from '../../../../translate';
import { AlertBox } from '../../../commons/components/AlertBox';
import { Input } from '../../../../components/Input';
import classes from './ValuationInteraction.module.css';
import { useTheme } from '../../../../theme/components/QuestioryThemeProvider/QuestioryThemeProvider.tsx';

interface FormValues {
	entries: Record<string, string>;
}

export const ValuationInteraction: React.FC<InteractionProps> = ({ presentationId, interaction, isDisabled, onInteractionEnd }) => {
	const config = interaction.configuration as ValuationInteractionConfigurationT;
	const { colors } = useTheme();
	const required = true; //config.required;
	const [error, setError] = useState<string | undefined>();
	const alreadySubmitted = hasSubmission(interaction.id);
	const defaultValues = config.statements.reduce(
		(acc, value) => {
			acc[value.id] = '';
			return acc;
		},
		{} as Record<string, string>
	);
	const {
		handleSubmit,
		formState: { isSubmitting },
		setValue,
		register,
		reset,
	} = useForm<FormValues>({
		defaultValues: {
			entries: defaultValues,
		},
	});

	const onSubmit = async (data: FormValues) => {
		const actualEntries: ValuationEntry[] = Object.entries(data.entries)
			//Filter all empty values
			.filter(([key, entry]) => {
				return key.trim() !== '' && entry !== undefined && entry.trim() !== '';
			})
			// Given the key which is the id get the actual statement from the config
			.map(([key, entry]) => {
				const parsedKey = parseInt(key, 10);
				const foundStatement = config.statements.find((statement) => statement.id === parsedKey);
				return {
					key: foundStatement!.statement, // Forcing uncheck given that we will always find a statement
					entry: parseInt(entry, 10), // Convert string to number
				};
			})
			// However in the strange case that a statement was not found, we filter it
			.filter((value) => !isEmpty(value.key));

		if (isEmpty(actualEntries) && required && !alreadySubmitted) {
			setError(translate('Please complete at least one answer before submitting'));
		} else if (!isEmpty(actualEntries)) {
			await saveValuationEntry(presentationId, interaction.id, actualEntries);
			recordSubmission(interaction.id);
			reset();
			onInteractionEnd();
		} else if (alreadySubmitted || !required) {
			onInteractionEnd();
		}
	};

	const SingleValuation = (field: ValuationConfigurationStatementItem) => {
		const [currentSliderValue, setCurrentSliderValue] = useState<number | undefined>();
		return (
			<div key={field.id} className='flex flex-col mb-2 shadow-lg rounded-3xl overflow-hidden gap-5 p-6' style={{ backgroundColor: colors.cardBackgroundColor }}>
				<div className='flex items-center justify-between'>
					<div className='w-[70%] min-w-0'>
						<p className='font-bold'>{field.statement}</p>
					</div>
					<div className='w-[25%] md:w-[20%]'>
						<Input
							type='number'
							min={config.minValue}
							max={config.maxValue}
							placeholder={`${String(config.minValue)} ${config.valueReference}`}
							autoComplete='off'
							inputMode='numeric'
							disabled={isDisabled}
							className={`bg-transparent rounded-xl focus:border-none focus:ring-0 focus:outline-none`}
							{...register(`entries.${field.id}`, {
								min: config.minValue,
								max: config.maxValue,
								onChange: (event) => setCurrentSliderValue(event.target.value),
							})}
							style={{
								borderColor: field.color,
							}}
						/>
					</div>
				</div>
				<div className='w-[90%] mx-auto relative'>
					<input
						type='range'
						min={config.minValue}
						max={config.maxValue}
						value={currentSliderValue || 0}
						disabled={isDisabled}
						onChange={(e) => {
							const value = e.target.value;
							setValue(`entries.${field.id}`, '' + value, {
								shouldDirty: true,
								shouldTouch: true,
							});
							setCurrentSliderValue(+value);
						}}
						className={`w-full appearance-none h-1 border-1 rounded-full focus:outline-none cursor-pointer ${classes.range} disabled:opacity-50 disabled:cursor-not-allowed`}
						style={{
							borderColor: field.color,
							backgroundColor: field.color,
							accentColor: field.color,
						}}
					/>
				</div>
			</div>
		);
	};

	return (
		<InteractionWrapper interaction={interaction} onSubmit={handleSubmit(onSubmit)} isSubmitDisabled={isSubmitting || isDisabled}>
			{config.maxValuePlaceholder && config.maxValuePlaceholder ? (
				<div className='max-w-[60%] mx-auto px-8 py-6 border rounded-3xl mb-8' style={{ borderColor: colors.brand, borderWidth: '1px' }}>
					<div className='flex items-center mb-2'>
						<p className='font-bold break-words'>{`${config.minValue} ${config.valueReference} - ${config.minValuePlaceholder}`}</p>
						<hr className='flex-1 mx-4 min-w-[2rem]' style={{ borderColor: colors.brand, borderWidth: '1px' }} />
						<p className='font-bold break-words'>{`${config.maxValue} ${config.valueReference} - ${config.maxValuePlaceholder}`}</p>
					</div>
				</div>
			) : null}
			{!isEmpty(error) ? <AlertBox title={error!} /> : undefined}
			{config.statements.length <= 2 ? (
				<div className='flex justify-center flex-wrap gap-4'>
					{config.statements.map((statement) => (
						<div key={statement.id} className='grow-[0.5]'>
							<SingleValuation key={statement.id} {...statement} />
						</div>
					))}
				</div>
			) : (
				<div id='grid' className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 flex-wrap'>
					{config.statements.map((statement) => (
						<SingleValuation key={statement.id} {...statement} />
					))}
				</div>
			)}
		</InteractionWrapper>
	);
};
