import React, { useEffect, useRef, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { motion } from 'framer-motion';
import { useRotatingWordsData } from '../../hooks/useRotatingWordsData.ts';
import { Loading } from '../../../commons/components/Loading';
import { VisualizationProps } from '../Visualization/Visualization.tsx';
import { useTheme } from '../../../../theme/components/QuestioryThemeProvider/QuestioryThemeProvider.tsx';
import { NoDataVisualizationMessage } from '../NoDataVisualizationMessage';

const TRANSITION_TIME_MS = 3000;
const WORD_TERM = '{{word}}';

export const RotatingWordsVisualization: React.FC<VisualizationProps> = ({ presentationId, visualization }) => {
	// const conf = visualization.configuration as RotatingWordsConfiguration;
	const { data, isLoading } = useRotatingWordsData(presentationId, visualization.id);
	const { colors } = useTheme();
	const [currentWord, setCurrentWord] = useState<string>('');
	const currentIndexRef = useRef(0);
	const hasData = !isLoading && !isEmpty(data);
	const parts = visualization.title.split(' ').filter((s) => s.trim());
	const getRandomWord = () => {
		if (isLoading || !data || data.length === 0) return '';
		const word = data[currentIndexRef.current % data.length]; // Get word sequentially using useRef
		currentIndexRef.current = (currentIndexRef.current + 1) % data.length; // Increment index for next call without causing re-renders
		return word;
	};

	// Initialize currentWord with the first word from data
	useEffect(() => {
		setCurrentWord(getRandomWord());
	}, [data]); // Empty dependency array to run only once after initial render

	useEffect(() => {
		const interval = setInterval(() => {
			setCurrentWord(getRandomWord());
		}, TRANSITION_TIME_MS);

		return () => clearInterval(interval);
	}, [data]);

	if (isLoading) {
		return <Loading />;
	}

	return (
		<div className='flex flex-col h-screen mx-14 justify-center items-center'>
			{hasData ? (
				<div className='flex w-4/5 gap-2 md:justify-center'>
					<h2 className='text-2xl font-bold break-words text-center '>
						{parts.map((part, index) => (
							<React.Fragment key={`${part}-${index}`}>
								{part === WORD_TERM ? (
									<motion.span key={currentWord} initial={{ opacity: 0, scale: 0.5 }} animate={{ opacity: 1, scale: 1 }} transition={{ duration: 0.5, ease: 'easeOut' }}>
										<span className={`inline-block rounded-lg px-4 mt-2 text-white text-center`} style={{ backgroundColor: colors.brand }}>
											{currentWord}
										</span>
									</motion.span>
								) : (
									part
								)}
								{/* Add a space after each part except for the last one */}
								{index < parts.length - 1 && ' '}
							</React.Fragment>
						))}
					</h2>
				</div>
			) : (
				<NoDataVisualizationMessage />
			)}
		</div>
	);
};
