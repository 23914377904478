import { Title } from '../../../commons/components/Title';
import { ContentConfigurationQuoteT, StaticContentT } from '../../model/StaticContent.ts';
import { useTheme } from '../../../../theme/components/QuestioryThemeProvider/QuestioryThemeProvider.tsx';

interface Props {
	content: StaticContentT;
}

export function ContentQuote({ content }: Props) {
	const conf = content.configuration as ContentConfigurationQuoteT;
	const { colors } = useTheme();
	return (
		<div id='quote-wrapper' className='m-auto w-[90%] xl:w-[80%] p-4'>
			<Title title={content.title} description={content.description} />
			<div className='border-l-4 pl-4 ml-2' style={{ borderColor: colors.brand }}>
				<em className='text-3xl italic whitespace-pre-wrap'>{conf.quote}</em>
			</div>
		</div>
	);
}
