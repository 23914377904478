import { useEffect, useRef } from 'react';
import { useSliderControls } from '../SliderControlsProvider/SliderControlsProvider.tsx';
import { MdCheck, MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { translate } from '../../../../translate';
import { Button } from '../../../../components/Button';
import { useTheme } from '../../../../theme/components/QuestioryThemeProvider/QuestioryThemeProvider.tsx';

interface Props {
	canSkip?: boolean;
	submitText?: string;
	isSubmitDisabled?: boolean;
	onSubmit?: () => void;
	onGoForward?: () => void; //Override default goForward behaviour
}

export const SlideArrows = ({ canSkip = true, onSubmit, submitText = translate('Submit'), isSubmitDisabled = false }: Props) => {
	const slider = useSliderControls();
	const buttonLeftRef = useRef<HTMLButtonElement>(null);
	const buttonRightRef = useRef<HTMLButtonElement>(null);
	const { colors } = useTheme();
	const handleOnGoForward = () => {
		slider.goForward();
	};

	useEffect(() => {
		const handleMoveBackward = (event: KeyboardEvent) => {
			if (event.key === 'ArrowLeft' && buttonLeftRef.current) {
				// buttonLeftRef.current.click();
			}
		};
		const handleMoveForward = (event: KeyboardEvent) => {
			if (event.key === 'ArrowRight' && buttonRightRef.current) {
				// buttonRightRef.current.click();
			}
		};

		// Attach the event listener when the component mounts
		window.addEventListener('keydown', handleMoveBackward);
		window.addEventListener('keydown', handleMoveForward);

		// Clean up the event listener when the component unmounts
		return () => {
			window.removeEventListener('keydown', handleMoveBackward);
			window.removeEventListener('keydown', handleMoveForward);
		};
	}, []);

	return (
		<>
			<div className='fixed left-0 bottom-0 w-full z-[1]' style={{ backgroundColor: colors.highlight }}>
				<div className='flex justify-center gap-4 my-2 h-12'>
					{!slider.presentation.hideControls && (
						<div className='flex justify-center'>
							<Button
								variant='invert'
								ref={buttonLeftRef}
								onClick={slider.goBack}
								disabled={!slider.canGoBack}
								display={!slider.canGoBack ? 'none' : 'inline-flex'}
								leftIcon={<MdChevronLeft size='1.6rem' />}
							>
								<span className='hidden md:block'>{translate('Prev')}</span>
							</Button>
						</div>
					)}
					{onSubmit !== undefined ? (
						<div className='flex justify-center'>
							<Button type='submit' className='px-4' onClick={onSubmit} disabled={isSubmitDisabled} rightIcon={<MdCheck size='1.2rem' />}>
								{submitText}
							</Button>
						</div>
					) : null}

					{!slider.presentation.hideControls && (
						<div className='flex justify-center'>
							<Button
								variant='invert'
								ref={buttonRightRef}
								onClick={handleOnGoForward}
								display={!canSkip || !slider.canGoForward ? 'none' : 'inline-flex'}
								disabled={!canSkip || !slider.canGoForward}
								rightIcon={<MdChevronRight size='1.6rem' />}
							>
								<span className='hidden md:block'>{onSubmit === undefined ? translate('Next') : translate('Skip')}</span>
							</Button>
						</div>
					)}
				</div>
			</div>
		</>
	);
};
