import { Title } from '../../../commons/components/Title';
import { Loading } from '../../../commons/components/Loading';
import { SelectedAnwserReference } from '../SelectedAnwserReference';
import React from 'react';
import { NoDataVisualizationMessage } from '../NoDataVisualizationMessage';

interface Props {
	title: string;
	description: string;
	isLoading: boolean;
	isNoData: boolean;
	reference?: string;
	referenceColor?: string;
}

export const VisualizationChartWrapper: React.FC<Props> = ({ title, description, isLoading, isNoData, reference, referenceColor }) => {
	return (
		<section id='chart-wrapper' className='h-[90%] overflow-y-auto flex flex-col'>
			<div className={`${!isLoading && isNoData ? 'm-auto' : 'm-6'} ${!isNoData ? 'mb-0' : ''}`}>
				<div className='mx-16'>
					<Title title={title} description={description} />
				</div>
				<SelectedAnwserReference reference={reference} circleColor={referenceColor} />
				{isLoading ? <Loading /> : null}
				{!isLoading && isNoData ? <NoDataVisualizationMessage /> : null}
			</div>
			<div id='chartdiv' className='h-[47rem]' />
		</section>
	);
};
