import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { useSliderControls } from '../../../presentation/components/SliderControlsProvider/SliderControlsProvider.tsx';

interface Props {
	title: string;
	description?: string;
}

export const Title: React.FC<Props> = ({ title, description }) => {
	const { currentSlide } = useSliderControls();
	const shoudRenderNull = currentSlide?.hideTitle && isEmpty(description);

	if (shoudRenderNull) {
		return null;
	}

	return (
		<div id='title-subtitle' className='flex flex-col my-3 rounded-3xl p-4 gap-4'>
			{!currentSlide?.hideTitle ? <h1 className='text-xl sm:text-3xl leading-[1.5] text-center break-words font-bold'>{title}</h1> : null}
			{!isEmpty(description) ? <h2 className='text-lg sm:text-2xl font-light leading-[1.5] text-center break-words mb-2'>{description}</h2> : null}
		</div>
	);
};
