import React, { useEffect, useRef, useState } from 'react';
import EmojiPicker, { Emoji, EmojiClickData, EmojiStyle, SkinTones } from 'emoji-picker-react';
import isEmpty from 'lodash/isEmpty';
import { LuSmilePlus } from 'react-icons/lu';
import { useTheme } from '../../../../theme/components/QuestioryThemeProvider/QuestioryThemeProvider.tsx';

interface Props {
	value: EmojiClickData['emoji'];
	error: boolean;
	disabled: boolean;
	onIconSelected: (emojiUnified: EmojiClickData['emoji']) => void;
}

export const IconPicker: React.FC<Props> = ({ value = '', error = false, disabled, onIconSelected }) => {
	const [flag, setFlag] = useState(false);
	const ref = useRef<HTMLDivElement>(null);

	const { colors } = useTheme();
	const handleClickOutside = (event: MouseEvent) => {
		if (ref.current && !ref.current.contains(event.target as Node)) {
			setFlag(false);
		}
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	const handleOnClick = (emoji: EmojiClickData) => {
		setFlag(false);
		onIconSelected(emoji.emoji);
	};

	return (
		<div ref={ref as React.LegacyRef<HTMLDivElement>}>
			{flag ? (
				<div className='absolute z-10 left-1/2 transform -translate-x-1/2 -translate-y-5 mt-[4.7rem]'>
					<EmojiPicker
						onEmojiClick={handleOnClick}
						autoFocusSearch={false}
						defaultSkinTone={SkinTones.LIGHT}
						emojiStyle={EmojiStyle.NATIVE}
						width='100%'
						allowExpandReactions={false}
						reactionsDefaultOpen={false}
						onReactionClick={handleOnClick}
					/>
				</div>
			) : null}
			<button
				disabled={disabled}
				onClick={(e) => {
					e.preventDefault();
					setFlag((f) => !f);
				}}
				className={`w-[66px] h-[66px] flex items-center justify-center rounded-full 
                    bg-buttonColor border 
                    hover:cursor-pointer
                    ${disabled && 'disabled:opacity-50 disabled:cursor-not-allowed'}
                    `}
				style={{
					backgroundColor: colors.buttonColor,
					borderColor: colors.brand,
				}}
			>
				{isEmpty(value) ? (
					<LuSmilePlus
						className={`w-8 h-8`}
						style={{
							color: !error ? colors.buttonTextColor : 'var(--color-red-500)',
						}}
					/>
				) : (
					<span className='text-4xl flex'>{value}</span>
				)}
			</button>
			<Emoji unified={value} />
		</div>
	);
};
