import React from 'react';
import { Title } from '../../../commons/components/Title';
import { VisualizationT } from '../../model/Visualization.ts';
import { NoDataVisualizationMessage } from '../NoDataVisualizationMessage';

interface Props {
	visualization: VisualizationT;
	isNoData: boolean;
	children: React.ReactNode;
}

export const VisualizationWrapper: React.FC<Props> = ({ visualization, isNoData, children }) => {
	return (
		<section className='h-[93%] overflow-y-auto flex'>
			<div className='m-auto'>
				<div className='mt-16 mx-16'>
					<Title title={visualization.title} description={visualization.description} />
				</div>
				{isNoData ? <NoDataVisualizationMessage /> : null}
				{children}
			</div>
		</section>
	);
};
