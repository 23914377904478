import { ContentConfigurationListT, StaticContentT } from '../../model/StaticContent.ts';
import { Title } from '../../../commons/components/Title';
import { FaCircle } from 'react-icons/fa';

interface Props {
	content: StaticContentT;
}

export function ContentList({ content }: Props) {
	const conf = content.configuration as ContentConfigurationListT;
	return (
		<div id='list-wrapper' className='m-auto w-[90%] xl:w-[80%] p-4'>
			<Title title={content.title} description={content.description} />
			<div className='flex justify-center h-[70%] mb-4'>
				<div className='flex flex-col w-[75%] lg:w-[60%] gap-4'>
					<ul className='space-y-4 ml-4'>
						{conf.labels.map((item, index) => (
							<li key={item + index}>
								<div className='flex items-center'>
									<FaCircle className='text-brand text-[0.9rem]' />
									<p className='font-medium text-lg md:text-xl lg:text-xl break-words ml-2'>{item}</p>
								</div>
							</li>
						))}
					</ul>
				</div>
			</div>
		</div>
	);
}
