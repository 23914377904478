import { VisualizationProps } from '../Visualization/Visualization.tsx';
import isEmpty from 'lodash/isEmpty';
import { VisualizationWrapper } from '../VisualizationWrapper';
import { usePillsData } from '../../hooks/usePillsData.ts';

export function PillsVisualization({ presentationId, visualization }: VisualizationProps) {
	const { data, isLoading } = usePillsData(presentationId, visualization.id);
	return (
		<VisualizationWrapper visualization={visualization} isNoData={!isLoading && isEmpty(data)}>
			{data?.map((category) => (
				<div id='wrapper' key={category.key} className='flex flex-col gap-6 my-8 mx-20'>
					<h1 className='text-2xl font-semibold'>{category.key}</h1>
					<div id='entries-wrapper' className='flex flex-wrap rounded-sm gap-4'>
						{category.entries.map((entry) => (
							<div key={entry} className='rounded-3xl px-4 py-2 text-center overflow-auto break-words' style={{ backgroundColor: category.color }}>
								<p className='text-xl leading-tight'>{entry}</p>
							</div>
						))}
					</div>
				</div>
			))}
		</VisualizationWrapper>
	);
}
