import { StaticContentT } from '../../model/StaticContent.ts';
import { Title } from '../../../commons/components/Title';

interface Props {
	content: StaticContentT;
}

export function ContentCover({ content }: Props) {
	return (
		<div id='cover-wrapper' className='m-auto w-[90%] xl:w-[80%] p-4'>
			<Title title={content.title} description={content.description} />
		</div>
	);
}
